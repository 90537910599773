const ROLES = {
    user: 110011 ,
    agent: 120012,
    data_capture:130013,
    validator:140014,
    admin:150015,
  };

export const Roles_list = ["USER", "AGENT", "DATA_CAPTURE" ,"VALIDATOR","ADMIN"]
 
export default ROLES