import React from 'react'
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { axiosPrivate } from "../../api/axios";
import useAuth from "../../hooks/useAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";

const LoginForm = ({setErrorMessage}) => {
    const LOGIN_URL = "/authentication/login";
    const { setAuth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const [logemail, setLogEmail] = useState(""); 
    const [logpassword, setLogPassword] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setErrorMessage("");
      }, [logemail, logpassword]);
    
      const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        let data ={
          email: logemail,
          password: logpassword
        }

        try {
          const response = await axiosPrivate.post(LOGIN_URL, {...data});
          const accessToken = response?.data?.accessToken;
          const role = response?.data?.role_id;
          const user_id = response?.data?.user_id;
          setAuth({ email:logemail, role, user_id, accessToken });
          setLogEmail("");
          setLogPassword("");
          navigate(from, { replace: true });
        } catch (err) {
          setLoading(false);
          if (!err?.response) {
            setErrorMessage("No response!");
          } else if (err.response?.status === 401 || err.response?.status === 400) {
            setErrorMessage(err.response?.data.message);
          } else {
            setErrorMessage("Login Failed");
          }
        }
      };


      
  return (
    <form onSubmit={handleLogin}>
        <div className="input-boxes">
        <div className="input-box">
        <i className="bi bi-envelope-at"></i>
            <input type="email" id="logemail" name="logemail"  value={logemail} onChange={(e) => setLogEmail(e.target.value)} placeholder="Enter your email" required/>
        </div>
        <div className="input-box">
        <i className="bi bi-file-earmark-lock2"></i>
            <input type="password" id="logpassword" name="logpassword"  value={logpassword} onChange={(e) => setLogPassword(e.target.value)} placeholder="Enter your password" required/>
        </div>
        <div className="text"><Link to={"/forgot_password"} className="reg-link">Forgot Password?</Link></div>
        <div className="button input-box">
          {loading ? <button  type="submit" ><FontAwesomeIcon icon={faArrowsRotate} className="spinner" /> </button>: <input type="submit" value="Submit"/> }
        </div>
        <div className="text sign-up-text">Don't have an account? <label htmlFor="flip">Signup now</label></div>
        </div>
    </form>
  )
}

export default LoginForm