import {useState, useEffect, useReducer} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser, faClock, faCheckCircle} from "@fortawesome/free-regular-svg-icons";
import { useParams } from 'react-router-dom';
import useAxiosFetch from '../hooks/useAxiosFetch';

const SingleTicketHistory
 = () => {
      const{id} = useParams()
      const [ticket, setTicket] = useState(null);
      const [_, forceUpdate] = useReducer((x) => x + 1, 0);
      const { data} = useAxiosFetch(`/ticket/single_ticket_history/${id}`,_);

      
  
  useEffect(() => {
          setTicket(data?.data);
        }, [data]);




  return (
    <div className='single-ticket-page-main'>
  
        <div className='stp-ticket-section'>
            <div className='ticket-details'>
               <div className='ticket-owner'><FontAwesomeIcon icon={faCircleUser}/> <span>{ticket?.user?.first_name || 'Null'} {ticket?.user?.last_name || 'Null'} </span></div>
               <div className='ticket-time'><FontAwesomeIcon icon={faClock}/> <span> {ticket?.createdAt.slice(0,10) || 'Null'}</span></div>
               <div className='ticket-status'><FontAwesomeIcon icon={faCheckCircle}/> <span> {ticket?.status || 'Null'}</span></div>
            </div> 

            <div className='ticket-title'> {ticket?.title || 'No Title'}</div>
            <div className='ticket-description'> {ticket?.description || 'No Description'}.</div>
        </div>


        <div className='stp-comments-section'> 
            <div className='ticket-comments history'>
              {ticket?.ticket_histories.length > 0 && ticket?.ticket_histories.map((historyRec) =>{
                       return (<div className='actual-comment right'>
                        <div className='comment-bubble'>
                          <div className='comment-time'> {historyRec?.user?.first_name} {historyRec?.user?.last_name}</div>
                          <div className='comment-txt'> {historyRec?.action}</div>
                          <div className='comment-time'> {historyRec?.createdAt}</div>
                        </div>
                      </div>)                   
                
              })}
            </div>
        </div>
    </div>
  )
}

export default SingleTicketHistory
