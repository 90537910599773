import { useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import DocumentTypes from '../uploadDocName';
import useAuth from '../../hooks/useAuth';



const AddKycDocumentsForm = ({forceUpdate, setSuccessMessage, setErrorMessage, data}) => {
    const [file, setFile] = useState('')
    const [category, setCategory] = useState('')
    const [loading, setLoading] =useState(false)
    const axiosPrivate  = useAxiosPrivate()
    const { auth } = useAuth();

    const options = DocumentTypes(`${data?.type}`)


    const handleUpload= async(e)=>{
      e.preventDefault()

      setLoading(true)
      setErrorMessage("")
      setSuccessMessage("")
      let formdata = new FormData()
      formdata.append('kycdoc', file)
      formdata.append('category', category)
      const headers = {
        'Content-Type': 'multipart/form-data'
      }

      try{
          const response = await axiosPrivate.post('/web/upload_kyc', formdata , {headers:{...headers}})
          setSuccessMessage(response?.data?.message)
          setCategory("")
          setFile("")
          forceUpdate()
          setLoading(false)
      }catch(err){
        setErrorMessage(err.response?.data?.message)
        setLoading(false)
      }
      }
  

  return (
    <form className='constant-form' onSubmit={handleUpload}>
    <p className='form-header '>Upload Documents</p>
    <div className="input-group">
      <span className="input-group-text" id="basic-addon2"><i className="bi bi-file-earmark-richtext"></i></span>
      <select className='border' value={category} onChange={(e)=>setCategory(e.target.value)}    required>
        <option value="">Category</option>
        {options && options.map((opt)=>{ return <option value={opt}>{opt}</option>})}
      </select>
    </div>

    <div className="input-group">
      <span className="input-group-text" id="basic-addon3"><i className="bi bi-cloud-arrow-up"></i></span>
       <input className='form-control' type='file' accept='application/pdf'  name='kycdoc'   onChange={(e)=>setFile(e.target.files[0])} placeholder='File'  required/>
    </div>

    {loading ?<button className='btn my-btn-blue' type='submit'><FontAwesomeIcon icon={faArrowsRotate} className='spinner'/></button> : <button className='btn my-btn-blue' type='submit'>Upload</button> }
  </form>
  )
}

export default AddKycDocumentsForm