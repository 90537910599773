import { useState } from "react";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import CURRENCY from "../../config.js/currency";

const AirtimeRegForm = ({
  data,
  forceUpdate,
  setSuccessMessage,
  setErrorMessage,
}) => {
  const [currency, setCurrency] = useState("");
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const options = CURRENCY;

  const handleReg = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    let data = {
      currency: currency,
    };

    try {
      const response = await axiosPrivate.post(`/web/activate_airtime_account/${auth?.user_id}`,{ ...data });
      setSuccessMessage(response?.data?.message);
      setCurrency("")
      forceUpdate();
    } catch (err) {
      setErrorMessage(err.response?.data?.message);
    }
  };
  return (
    <div className="edit-user-form-container">
      <form className="edit-user-form" onSubmit={handleReg}>
        <div className="input-group">
          <span className="input-group-text" id="basic-addon3"><i className="bi bi-currency-exchange"></i></span>
          <select className="border" value={currency} onChange={(e) => setCurrency(e.target.value)} placeholder="Amount"required >
            <option value="">currency</option>
            {options &&
              options.map((opt) => {
                return <option value={opt}>{opt}</option>;
              })}
          </select>
        </div>

        <button type="submit" className="edit-user-btn">
          Add Account
        </button>
      </form>
    </div>
  );
};

export default AirtimeRegForm;
