import React from 'react'
import { useState, useEffect, useReducer} from 'react'
import useAxiosFetch from '../hooks/useAxiosFetch'
import { useParams } from "react-router-dom";

const SingleManual = () => {
    const {id} = useParams();
    const [stats, setStats] = useState(null);
    const [_, forceUpdate] = useReducer((x) => x + 1, 0);
    const { data} = useAxiosFetch( `/rep/manual_purchase/${id}`, _);
  

    useEffect(() => {
      setStats(data?.data);
    }, [data]);
 

  return (
    <div className='search-trans-main'>

        <div className='st-midpart'>
            <div className='with-data'>
                <div className='info-strip'>
                        <div className='user-info'>
                            <div className='info-header'>Full Name</div>
                            <div className='info-details'>{stats?.user?.first_name} {stats?.user?.last_name}</div>
                        </div>
                        <div className='user-info'>
                            <div className='info-header'>Email</div>
                            <div className='info-details'>{stats?.user?.email}</div>
                        </div>
                        <div className='user-info'>
                            <div className='info-header'>Account #</div>
                            <div className='info-details'>{stats?.account_number}</div>
                        </div>
                </div>
                <div className='info-strip'>
                        <div className='user-info'>
                            <div className='info-header'>Ref ID</div>
                            <div className='info-details'>{stats?.reference_id}</div>
                        </div>
                        <div className='user-info'>
                            <div className='info-header'>Currency</div>
                            <div className='info-details'>{stats?.currency}</div>
                        </div>
                        <div className='user-info'>
                            <div className='info-header'>Status</div>
                            <div className='info-details'>{stats?.transaction_status}</div>
                        </div>

                </div>
                <div className='info-strip'>

                        <div className='user-info'>
                            <div className='info-header'>Channel</div>
                            <div className='info-details'>{stats?.channel}</div>
                        </div>
                        <div className='user-info'>
                            <div className='info-header'>Gateway</div>
                            <div className='info-details'>{stats?.payment_gateway}</div>
                        </div>
                        <div className='user-info'>
                            <div className='info-header'>Done By</div>
                            <div className='info-details'>{stats?.purchaser?.email}</div>
                        </div>
                </div>
                <div className='info-strip'>

                        <div className='user-info'>
                            <div className='info-header'>Ammount Purchased</div>
                            <div className='info-details numc'>{stats?.amount_purchased}</div>
                        </div>
                        <div className='user-info'>
                            <div className='info-header'>Ammount Paid</div>
                            <div className='info-details numc'>{stats?.amount_paid}</div>
                        </div>
                </div>
            </div>
            {/* <div className='no-data'>NO DATA</div> */}
        </div>
    </div>
  )
}

export default SingleManual