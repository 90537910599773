import React from "react";
import { useState } from "react";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const EditProfileForm = ({
  data,
  forceUpdate,
  setSuccessMessage,
  setErrorMessage,
}) => {
  const [name, setName] = useState(data?.name);
  const [type, setType] = useState(data?.type);
  const [country, setCountry] = useState(data?.country);
  const [city, setCity] = useState(data?.city);
  const [address, setAddress] = useState(data?.address);
  const [code, setCode] = useState(data?.country_code);
  const [cell, setCell] = useState(data?.cell);
  const [companyEmail, setCompanyEmail] = useState(data?.company_email);
  const [contactDetails, setContactDetails] = useState(data?.contact_details);
  const { auth} = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const handleProfile = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    let data = {
      name: name,
      type: type,
      country: country,
      city: city,
      address: address,
      country_code: code,
      cell: cell,
      company_email: companyEmail,
      contact_details: contactDetails,
    };

    try {
      const response = await axiosPrivate.post(
        `/web/update_profile/${auth?.user_id}`,
        { ...data }
      );
      setSuccessMessage(response?.data?.message);
      forceUpdate();
    } catch (err) {
      setErrorMessage(err.response?.data?.message);
    }
  };

  return (
    <div className="edit-user-form-container">
      <form className="company-reg-form" onSubmit={handleProfile}>
        <div className="input-group">
          <span className="input-group-text" id="basic-addon3"><i className="bi bi-building"></i> </span>
          <input className="border" type="text" name="name" value={name} onChange={(e) => setName(e.target.value)} placeholder="name" required/>
        </div>

        <div className="input-group">
          <span className="input-group-text" id="basic-addon1">
            <i className="bi bi-check2-circle"></i>
          </span>
          <select className="border" value={type} onChange={(e) => setType(e.target.value)} required>
            <option value="">type</option>
            <option value="COMPANY">COMPANY</option>
            <option value="PARTNERSHIP">PARTNERSHIP</option>
            <option value="SOLE_TRADER">SOLE_TRADER</option>
            <option value="NGO_CHURCH">NGOs & CHURCHES</option>
            <option value="SOCIETY">SOCIETY, ASSOCIATION & CLUBS</option>
          </select>
        </div>

        {/* <div className="input-group">   
            <span className="input-group-text" id="basic-addon2"><i className="bi bi-currency-exchange"></i></span>
            <select className='border' value={country} onChange={(e)=>setCountry(e.target.value)}    required>
                <option value="">Country</option>
                <option value="ZIG">ZIG</option>
                <option value="USD">USD</option>
            </select>
        </div> */}

        <div className="input-group">
          <span className="input-group-text" id="basic-addon2"><i className="bi bi-flag"></i></span>
          <input className="border" type="text" name="country" value={country} onChange={(e) => setCountry(e.target.value)} placeholder="Country" required/>
        </div>
        <div className="input-group">
          <span className="input-group-text" id="basic-addon4"><i className="bi bi-house"></i></span>
          <input className="border" type="text" name="city" value={city} onChange={(e) => setCity(e.target.value)} placeholder="city" required/>
        </div>
        <div className="input-group">
          <span className="input-group-text" id="basic-addon5"><i className="bi bi-signpost-2"></i></span>
          <input className="border" type="text" name="address" value={address} onChange={(e) => setAddress(e.target.value)} placeholder="Street Address" required/>
        </div>
        <div className="input-group">
          <span className="input-group-text" id="basic-addon6"><i className="bi bi-telephone"></i></span>
          <input className="border dwone" type="text" name="code" value={code} onChange={(e) => setCode(e.target.value)} placeholder="code" required/>
          <input className="border dtwo" type="number" name="cell" value={cell} onChange={(e) => setCell(e.target.value)} placeholder="cell" required/>
        </div>
        <div className="input-group">
          <span className="input-group-text" id="basic-addon7"><i className="bi bi-envelope-at"></i>
          </span>
          <input className="border" type="email" name="companyEmail" value={companyEmail} onChange={(e) => setCompanyEmail(e.target.value)} placeholder="company email" required/>
        </div>
        <div className="input-group">
          <span className="input-group-text" id="basic-addon8"><i className="bi bi-telephone"></i>
          </span>
          <input className="border" type="text" name="contactDetails" value={contactDetails} onChange={(e) => setContactDetails(e.target.value)} placeholder="contact details" required/>
        </div>

        <button className="edit-user-btn" type="submit">Save</button>
      </form>
    </div>
  );
};

export default EditProfileForm;
