
import { useParams } from "react-router-dom";
import axios from "../api/axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DocumentTitleNoneShared from "../hooks/DocumentTitleNoneShared";
import logo from "../img/logo.png"


const VerifyEmail = () => {
      DocumentTitleNoneShared("Verify Email");
      const [success, setSuccess] = useState(false)
      const [errorMessage, setErrorMesage] = useState("")
      const { id, token } = useParams()
      const RESET_URL = `/authentication/email_verification/${id}/${token}`;
      const navigate =  useNavigate()

       

      const handleVerfification = async()=>{
          try{
            const response = await axios.get(RESET_URL)
            setSuccess(true)
          }catch(err){
            setSuccess(false)
            setErrorMesage(err?.response?.data?.message)
          }
      }
       const handleHome = ()=>{
        navigate("/login")
       }
      useEffect(()=>{
        handleVerfification()
      }, [])

  return (
    <div className='verify-email-main'>
        <div className='log-logo'>
            <div className='log-logo-image'>
                <img src={logo} alt='Logo'/>
            </div>
        </div>
        <div className='verify-email-container'>
            {success && (
              <>
              <div className='verify-email-message'>You have Successfully verified Your Email.</div>
              <div className='verify-email-icon'><i className="bi bi-check-circle"></i></div>
              </>)} 
            {!success && errorMessage && (
              <>
                <div className='verify-email-message'>{errorMessage}!</div>
                <div className='verify-email-icon error'><i className="bi bi-x-octagon"></i></div>
              </>)}
            {!success && !errorMessage && (
              <>
                <div className='verify-email-message'>Loading .............</div>
                <div className='verify-email-icon'><i className="bi bi-arrow-clockwise spinner"></i></div>
              </>)}
              <div className='verify-email-button'>
                  <button className='ve-btn' onClick={handleHome}>Home</button>
                  <button className='ve-btn' onClick={handleHome}>Login</button>
              </div>
          </div>

          <div className='log-icon-container'>
             <div className='log-icons'>
                <a href="https://www.facebook.com/omnicontactbpo/" target="_blank" ><i className="bi bi-facebook"></i></a>
                <a href="https://zw.linkedin.com/company/omnicontactbpo" target="_blank" ><i className="bi bi-linkedin"></i></a>
                <a href="https://twitter.com/omnicontact_bpo" target="_blank" ><i className="bi bi-twitter-x"></i></a>
                <a href="ttps://www.youtube.com/@omnicontact5089"  target="_blank"><i className="bi bi-youtube"></i></a>
                <a href="mailto: info@omnicontact.biz"><i className="bi bi-envelope"></i></a>
             </div>
        </div>
    </div>
  )
}

export default VerifyEmail