
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import COLORS from '../config.js/colors';





const SampleBar = ()=> {
//   const [data, setData] = useState(null)
//   const [constantData, setConstantData] = useState(null)

  const data = [
  {
    name: 'JAN',
    sales: 200,
  },
  {
    name: 'FEB',
    sales: 168,
  },
  {
    name: 'MAR',
    sales: 140,
  },
  {
    name: 'APR',
    sales: 100,
  },
  {
    name: 'MAY',
    sales: 89,
  },
  {
    name: 'JUN',
    sales: 250,
  },
  {
    name: 'JUL',
    sales: 124,
  },
  {
    name: 'AUG',
    sales: 130,
  },
  {
    name: 'SEP',
    sales: 110,
  },
  {
    name: 'OCT',
    sales: 230,
  },
  {
    name: 'NOV',
    sales: 50,
  },
  {
    name: 'DEC',
    sales: 75,
  },
];

//   useEffect(()=>{
//     let newDataSet = GeneratProductivityReport(productivityData)
//     setConstantData(newDataSet)
//     setData(newDataSet.slice(59))
//   },[productivityData])



  return (
    <div className="main-chat-div">
            <ResponsiveContainer width="100%" height="100%">
                <BarChart width={500} height={300} data={data} margin={{ top: 20, right: 10, left: 10, bottom: 5, }} >
                <CartesianGrid strokeDasharray="0" vertical={false}  />
                <XAxis dataKey="name"  fontSize={12} axisLine={false} />
                <YAxis axisLine={false} fontSize={12} label={{ value: 'Sales', angle: -90, position: 'insideLeft', textAnchor: 'middle' }} />
                <Tooltip />
                <Legend />
                <Bar dataKey="sales" barSize={20} fill={COLORS.headerBG} />
                </BarChart>
            </ResponsiveContainer>              
    </div>
  );
}

export default SampleBar;




