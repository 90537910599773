import React from "react";
import { useState } from "react";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const EditUserForm = ({data,forceUpdate,setSuccessMessage,setErrorMessage,}) => {
  const [first_name, setFirstName] = useState(data?.first_name);
  const [last_name, setLastName] = useState(data?.last_name);
  const [email, setEmail] = useState(data?.email);
  const { auth} = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    let data = {
      first_name: first_name,
      last_name: last_name,
      email: email,
    };

    try {
      const response = await axiosPrivate.post(
        `/web/update_details/${auth?.user_id}`,
        { ...data }
      );
      setSuccessMessage(response?.data?.message);
      forceUpdate();
    } catch (err) {
      setErrorMessage(err.response?.data?.message);
    }
  };

  return (
    <div className="edit-user-form-container">
      <form className="edit-user-form" onSubmit={handleSubmit}>
        <div className="input-group">
          <span className="input-group-text" id="basic-addon3"><i className="bi bi-person-bounding-box"></i></span>
          <input className="border" type="text" name="first_name" value={first_name} onChange={(e) => setFirstName(e.target.value)}  required/>
        </div>
        <div className="input-group">
          <span className="input-group-text" id="basic-addon4"><i className="bi bi-person-bounding-box"></i></span>
          <input className="border" type="text" name="last_name" value={last_name} onChange={(e) => setLastName(e.target.value)}  required/>
        </div>
        <div className="input-group">
          <span className="input-group-text" id="basic-addon4"><i className="bi bi-envelope-at"></i></span>
          <input className="border" type="eamil" name="email" value={email} onChange={(e) => setEmail(e.target.value)}  required/>
        </div>
        <button className="edit-user-btn" type="submit">Save</button>
      </form>
    </div>
  );
};

export default EditUserForm;
