
import { Stack, Button } from "@mui/material";
import {DataGrid,GridToolbarContainer} from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import COLORS from "../config.js/colors";

 
const SalesTable = ({data, setShowModal, setSelectedRow}) => {
  const columns = [
    { field: "id", headerName: "ID", width: 67 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
    { field: "reference_id", headerName: "REF", width: 300 , headerClassName: 'super-app-theme--header',headerAlign: 'center',align: 'center' },
    { field: "amount_sold", headerName: "Amount", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',align: 'center' },
    { field: "reciever_number", headerName: "Mobile", width: 150  , headerClassName: 'super-app-theme--header',headerAlign: 'center',align: 'center', valueFormatter: ({ value }) => value ? `${value.slice(0,6)}XXXXXX`:"XXXXXX"},
    { field: "currency", headerName: "Currency", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center', align: 'center'},
    { field: "channel", headerName: "Channel", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
    { field: "transaction_status", headerName: "Status", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',align: 'center'},
    { field: "createdAt", headerName: "Date", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',align: 'center'},
    {
      field: "action",
      headerName: "Actions",
      width: 150,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      disableClickEventBubbling: true,

      renderCell: (params) => {
        
          const handleNavigateMore = (e) => {
                          const currentRow = params.row;
                          setSelectedRow(currentRow)
                          setShowModal(true)
                          return;
                        };
        return (
          <Stack direction="row" spacing={2}>
            <Button variant="text" color="primary" size="small" onClick={handleNavigateMore}>
              <FontAwesomeIcon icon={faInfoCircle} />
            </Button>
          </Stack>
        );
      },
    },
  ];
  function getRowId(row) {
    return row.id;
  }

  function MyExportButton() {
    return (
      <GridToolbarContainer>
      </GridToolbarContainer>
    );
  }

  return (
    <div className="sales-data-component">
          <DataGrid 
          rows={data} 
          getRowId={getRowId} 
          columns={columns} 
          sx={{ fontSize: 12,  width:"100%" ,fontWeight:500,"& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {backgroundColor: COLORS.headerBG},'& .super-app-theme--header': {backgroundColor: COLORS.headerBG, fontWeight:"bold",color:COLORS.hearderTXT}  }} 
          rowHeight={70} 
          checkboxSelection={true}  
          getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'} />
   
    </div>
    
  );
};

export default SalesTable;
