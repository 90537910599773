import React from 'react'
import {DataGrid,GridToolbarColumnsButton,GridToolbarExport,GridToolbarContainer} from "@mui/x-data-grid";
import COLORS from '../../config.js/colors';


const UserDiscountsTable = ({data}) => {
    const columns = [
        { field: "id", headerName: "ID", width: 200 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
        { field: "user", headerName: "Email", width: 200, headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center', valueFormatter: ({ value }) => value?.email },
        { field: "title", headerName: "Title", width: 200 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
        { field: "value", headerName: "Value", width: 200 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
        { field: "currency", headerName: "Currency", width: 200, headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
        { field: "createdAt", headerName: "Date", width: 200 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
    ];
    function getRowId(row) {
        return row.id;
      }
  

      function MyExportButton() {
        return (
          <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarExport   csvOptions={{ fileName: 'user_discounts'}}/>
          </GridToolbarContainer>
        );
      }

  return (
    <div className="sales-data-component">
       <DataGrid 
          rows={data} 
          getRowId={getRowId} 
          columns={columns} 
          slots={{toolbar:MyExportButton}}
          sx={{ fontSize: 12,  width:"100%" ,fontWeight:500,"& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {backgroundColor: COLORS.headerBG},'& .super-app-theme--header': {backgroundColor: COLORS.headerBG, fontWeight:"bold",color:COLORS.hearderTXT}  }} 
          rowHeight={70} 
          checkboxSelection={true}  
          getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'}
          />
    </div>
  )
}

export default UserDiscountsTable