import {useEffect,useState, useReducer} from 'react'
import useAxiosFetch from '../../hooks/useAxiosFetch';
import UserDiscountsTable from '../../components/tables/userDiscountsTable';
import DocumentTitle from '../../hooks/DocumentTitle';

const UserDiscountsReport = () => {

  DocumentTitle("Discount Report");
  const [stats, setStats] = useState(null);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const { data} = useAxiosFetch("/rep/user_discounts",_);

  useEffect(() => {
    setStats(data?.data);
    }, [data]);
        
    

  return (
    <div className="user-main-container">
    <div className="user-container">
      <div className="user-header">
        <div className="sub-header">All Time</div>
        <div className="main-header">User Discounts Report</div>      
      </div>
      <div className="user-table-container rep">
       {stats && <UserDiscountsTable data={stats}/>}
      </div>
    </div>
  </div>
  );
}

export default UserDiscountsReport