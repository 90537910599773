
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import COLORS from "../config.js/colors";

const RecentTransactions = ({data}) => {
    const columns = [
        { field: "id", headerName: "#", width: 67 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
        { field: "transaction_ref", headerName: "REF", width: 250 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
        { field: "account_number", headerName: "Acc #", width: 250 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
        { field: "type", headerName: "Type", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
        { field: "channel", headerName: "Channel", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
        { field: "transaction_status", headerName: "Status", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
        { field: "placed_on", headerName: "Date", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
      ];
      function getRowId(row) {
        return row.id;
      }
    
      function MyExportButton() {
        return <GridToolbarContainer></GridToolbarContainer>;
      }
    
      return (
        <div className="sales-data-component">
          <DataGrid
            rows={data}
            getRowId={getRowId}
            columns={columns}
            sx={{ fontSize: 12,  width:"100%" ,fontWeight:500,"& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {backgroundColor: COLORS.headerBG},'& .super-app-theme--header': {backgroundColor: COLORS.headerBG, fontWeight:"bold",color:COLORS.hearderTXT}  }} 
            rowHeight={70}  
            getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'}
          />
        </div>
      );
}

export default RecentTransactions