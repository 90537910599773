import { useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from '../../hooks/useAuth';
import ROLES from '../../config.js/roles';


const SearchByDateForm = ({ setStats, setSuccessMessage,  setErrorMessage,}) => {
  const [startDate, setStartDate] = useState("");
  const [loading, setLoading] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage("");
    setSuccessMessage("");
    let data = {
      start_date: startDate,
      end_date: startDate,
    };

    try {
      const response = await axiosPrivate.post(auth?.role=== ROLES.user  ? `/webtrans/search_sales_by_date/${auth?.user_id}`:`/webtrans/search_sales_by_date`, {...data });
      setSuccessMessage("Here are your search results");
      setStats(response?.data?.data)
      setStartDate("")
      setLoading(false);
    } catch (err) {
      setErrorMessage(err.response?.data?.message);
      setLoading(false);
    }
  };
  return (
    <div className="search-date-form-container">
      <div className="search-date-form-hearder">Search By Date</div>
      <form className="search-date-form" onSubmit={handleSearch}>
      
        <div className="input-group">
          <span className="input-group-text" id="basic-addon3">Date</span>
          <input className="border" type="date" name="startDate" value={startDate} onChange={(e) => setStartDate(e.target.value)}  required/>
        </div>

        {loading ? <button className="search-date-btn" type="submit"> <FontAwesomeIcon icon={faArrowsRotate} className="spinner" /> </button> : <button className="search-date-btn" type="submit"> search</button>}
      </form>
    </div>
  );
};

export default SearchByDateForm;
