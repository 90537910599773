

const PageNotFound = () => {
  return (
    <div className="not-found-container">
          <div className="nf-number">404</div>
          <div className="nf-text">Ooops! page not found</div>
    </div>
  )
}

export default PageNotFound