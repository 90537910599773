import React from "react";
import { useState } from "react";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const EditPasswordrForm = ({ setSuccessMessage, setErrorMessage }) => {
  const [current_password, setCurrentPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  const { auth} = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    let data = {
      previous_password: current_password,
      new_password: new_password,
    };

    try {
      const response = await axiosPrivate.post(`/web/update_password/${auth?.user_id}`,{ ...data });
      setSuccessMessage(response?.data?.message);
      setCurrentPassword("")
      setNewPassword("")
    } catch (err) {
      setErrorMessage(err.response?.data?.message);
    }
  };

  return (
    <div className="edit-user-form-container">
      <form className="edit-user-form" onSubmit={handlePasswordChange}>
        <div className="input-group">
          <span className="input-group-text" id="basic-addon3"><i className="bi bi-file-lock"></i></span>
          <input className="border" type="password" name="current_password" value={current_password} onChange={(e) => setCurrentPassword(e.target.value)} placeholder="Current Password" required/>
        </div>
        <div className="input-group">
          <span className="input-group-text" id="basic-addon4"><i className="bi bi-file-lock"></i></span>
          <input className="border" type="password" name="new_password" value={new_password} onChange={(e) => setNewPassword(e.target.value)} placeholder="new Password" required/>
        </div>

        <button className="edit-user-btn" type="submit">Reset</button>
      </form>
    </div>
  );
};

export default EditPasswordrForm;
