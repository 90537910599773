import React from 'react'
import { useState, useEffect,useReducer } from 'react';
import Alert from '../components/alert';
import useAxiosFetch from '../hooks/useAxiosFetch'
import useAuth from '../hooks/useAuth';
import DocumentTitle from '../hooks/DocumentTitle';
import KycDocument from '../components/kycDocument';
import AddKycDocumentsForm from '../components/forms/addKycForm';

const KycUploadPage = () => {
    DocumentTitle('Upload Documents')
    const { auth } = useAuth();
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const [stats, setStats] = useState(null);
    const [_, forceUpdate] = useReducer((x) => x + 1, 0);

    const { data } = useAxiosFetch(`web//kyc_and_profile/${auth?.user_id}`, _);
    useEffect(() => {
      setStats(data?.data);
    }, [data]);



    return (
        <div className='settings-page'>
            <div className='settings-sections cust'>
                {errorMessage && <Alert message={errorMessage} setMessage={setErrorMessage} type={"error"}/>}
                {successMessage && <Alert message={successMessage} setMessage={setSuccessMessage} type={"success"}/>}
                <div className="section-header">
                <div className="sub-header">All Uploads</div>
                <div className="main-header">Dealer Documents Upload</div>
                </div>
                <div className='actual-constants'>
                   {stats && stats?.kyc_docs ? <KycDocument data={stats?.kyc_docs}/> : <div className='no-data-sec'> No Documents Available</div>}
                </div>
                
                <div className='constants-update-section'>
                   {stats  && stats?.dealer_profile ? <AddKycDocumentsForm setErrorMessage={setErrorMessage} setSuccessMessage={setSuccessMessage} forceUpdate={forceUpdate} data={stats?.dealer_profile}/> : <div className='no-data-sec'> No Company Profile Available</div>}
                </div>
            </div>
     </div>
  )
}

export default KycUploadPage