import DocumentTitle from '../../hooks/DocumentTitle';
import useAxiosFetch from '../../hooks/useAxiosFetch'
import { useState, useEffect,useReducer } from 'react';
import useAuth from '../../hooks/useAuth';
import SalesRepTable from '../../components/SalesRepTable';
import ROLES from '../../config.js/roles';
import Alert from '../../components/alert';
import SearchByDateRangeForm from '../../components/forms/searchByDateRangeForm';

const Salesreport = () => {
    DocumentTitle("Sales Report");
    const { auth} = useAuth();
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const [stats, setStats] = useState(null);
    const [_, forceUpdate] = useReducer((x) => x + 1, 0);


  
  const { data } = useAxiosFetch(auth?.role=== ROLES.user  ? `/webtrans/sales/${auth?.user_id}`:  `/webtrans/sales`, _);

  useEffect(() => {
    setStats(data?.data);
  }, [data]);

  return (
    <div className="sales-main-container">
      {errorMessage && <Alert message={errorMessage} setMessage={setErrorMessage} type={"error"}/>}
      {successMessage && <Alert message={successMessage} setMessage={setSuccessMessage} type={"success"}/>}
      <div className="sales-container">
        <div className="sales-header-cus">
          <div className='left-sect'>
          <div className="sub-header">Latest</div>
          <div className="main-header">Sales Report</div>
          </div>
          <div className='right-sect'>
            <SearchByDateRangeForm setStats={setStats} setErrorMessage={setErrorMessage} setSuccessMessage={setSuccessMessage}/>
          </div>
        </div>
      <div className="sales-table-container toosho">
         {stats && <SalesRepTable data={stats} />}
      </div>
    </div>
  </div> 
  )
}

export default Salesreport