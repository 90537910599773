import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import Alert from "../alert";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const DeleteAccountModal = ({ setShowModal }) => {
  const DELETION_URL = `web/delete_account`;
  const [errorMessage, setErrorMessage] = useState("");
  const [password, setPassword] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const { setAuth, } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setErrorMessage("");
  }, []);

  const handleClose = () => {
    setShowModal(false);
  };

  const handleSetDiscount = async (e) => {
    e.preventDefault();
    setErrorMessage("");

    const sdata = {
      password: password,
    };

    try {
      const response = await axiosPrivate.post(DELETION_URL, { ...sdata });
      setAuth({ email: "", role: "", user_id: "", accessToken: "" });
      navigate("/login", { replace: true });
    } catch (err) {
      if (!err?.response) {
        setErrorMessage("No server response!");
      } else {
        setErrorMessage(err.response?.data?.message);
      }
    }
  };
  return (
    <div className="inner-modal-main">
      <div className="inner-modal-body smallc">
        <button className="inner-close-modal-btn" onClick={handleClose}>
          <FontAwesomeIcon icon={faCircleXmark} />
        </button>
        {errorMessage && ( <Alert message={errorMessage} setMessage={setErrorMessage} type={"error"}/>)}
        <div className="edit-user-form-container">
          <form className="edit-user-form" onSubmit={handleSetDiscount}>
            <div className="modal-heading">Complete Account Deletion</div>
            <div className="input-group">
              <span className="input-group-text" id="basic-addon3">
                <i className="bi bi-lock"></i>
              </span>
              <input className="border" type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Enter Password" required/>
            </div>

            <button type="submit bg-danger" className="edit-user-btn"> Delete </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccountModal;
