
import { Stack, Button } from "@mui/material";
import {DataGrid,GridToolbarContainer} from "@mui/x-data-grid";
import COLORS from "../../config.js/colors";

 
const NotificationUsers = ({data, setShowModal, setSelectedRow}) => {
  const columns = [
    { field: "id", headerName: "ID", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
    { field: "user_id", headerName: "User ID", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
    { field: "user", headerName: "email", width: 300 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center', valueFormatter: ({ value }) => value?.email } ,
    { field: "createdAt", headerName: "Date", width: 300 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
    {
      field: "action",
      headerName: "Actions",
      width: 150, 
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',  
      align: 'center',
      sortable: false,
      disableClickEventBubbling: true,

      renderCell: (params) => {
        
          const handleNavigateMore = (e) => {
                          const currentRow = params.row;
                          setSelectedRow(currentRow)
                          setShowModal(true)
                          return;
                        };
        return (
          <Stack direction="row" spacing={2}>
            <Button variant="text" color="warning" size="small" onClick={handleNavigateMore}><i className="bi bi-trash3"></i></Button>
          </Stack>
        );
      },
    },
  ];
  function getRowId(row) {
    return row.id;
  }

  function MyExportButton() {
    return (
      <GridToolbarContainer>
      </GridToolbarContainer>
    );
  }

  return (
    <div className="sales-data-component">
          <DataGrid
            rows={data}
            getRowId={getRowId}
            columns={columns}
            sx={{ fontSize: 12,  width:"100%" ,fontWeight:500,"& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {backgroundColor: COLORS.headerBG},'& .super-app-theme--header': {backgroundColor: COLORS.headerBG, fontWeight:"bold",color:COLORS.hearderTXT}  }} 
            rowHeight={70} 
            checkboxSelection={true}  
            getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'}
          />
    </div>
  );
};

export default NotificationUsers;
