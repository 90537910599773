import { useEffect } from "react";


const DocumentTitle= (title)=> {
  useEffect(() => {
    document.title = title || 'Bulk Airtime';
    document.getElementById("nav-title").textContent = title ;

  }, [title]);

}

export default DocumentTitle;
