
import DocumentTitle from '../hooks/DocumentTitle'
import { useState} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import Alert from '../components/alert';


const ProfileRegPage = () => {
    DocumentTitle('Dealer Registration')
    const [name, setName] = useState('')
    const [type, setType] = useState('')
    const [country, setCountry] = useState('')
    const [city, setCity] = useState('')
    const [address, setAddress] = useState('')
    const [code, setCode] = useState('')
    const [cell, setCell] = useState('')
    const [companyEmail, setCompanyEmail] = useState('')
    const [contactDetails, setContactDetails] = useState('')
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const [loading, setLoading] =useState(false)
    const axiosPrivate  = useAxiosPrivate()

    const handleRegister= async(e)=>{
        e.preventDefault()
        setLoading(true)
        setErrorMessage("")
        setSuccessMessage("")
        let data={
            name: name,
            type: type,
            country: country,
            city: city,
            address: address,
            country_code: code,
            cell: cell,
            company_email: companyEmail,
            contact_details: contactDetails,
     }
  
        try{
            const response = await axiosPrivate.post(`/web/register_profile`, {...data})
            setSuccessMessage(response?.data?.message)
            setLoading(false)
            setName("")
            setType("")
            setCountry("")
            setCell("")
            setAddress("")
            setCode("")
            setCell("")
            setCompanyEmail("")
            setContactDetails("")
        }catch(err){
          setErrorMessage(err?.response?.data?.message)
          setLoading(false)
        }
        }
    


    return (
     <div className='settings-page'>
       <div className='settings-sections'>
       {errorMessage && <Alert message={errorMessage} setMessage={setErrorMessage} type={"error"}/>}
        {successMessage && <Alert message={successMessage} setMessage={setSuccessMessage} type={"success"}/>}
       <div className="section-header">
          <div className="sub-header">new registration</div>
          <div className="main-header">Register Dealer Profile</div>
        </div>
        <div className='actual-constants fullc'>
            <form className='company-reg-form' onSubmit={handleRegister}>
 
                <div className="input-group">
                    <span className="input-group-text" id="basic-addon3"><i className="bi bi-buildings"></i></span>
                    <input className='border' type='text'  name='name'  value={name} onChange={(e)=>setName(e.target.value)} placeholder='name'  required/>
                </div>

                <div className="input-group">   
                    <span className="input-group-text" id="basic-addon1"><i className="bi bi-check2-circle"></i></span>
                    <select className='border' value={type} onChange={(e)=>setType(e.target.value)}    required>
                        <option value="">type</option>
                        <option value="COMPANY">COMPANY</option>
                        <option value="PARTNERSHIP">PARTNERSHIP</option>
                        <option value="SOLE_TRADER">SOLE_TRADER</option>
                        <option value="NGO_CHURCH">NGOs & CHURCHES</option>
                        <option value="SOCIETY">SOCIETY, ASSOCIATION & CLUBS</option>
                    </select>
                </div>

                {/* <div className="input-group">   
                    <span className="input-group-text" id="basic-addon2"><i className="bi bi-currency-exchange"></i></span>
                    <select className='border' value={country} onChange={(e)=>setCountry(e.target.value)}    required>
                        <option value="">Country</option>
                        <option value="ZIG">ZIG</option>
                        <option value="USD">USD</option>
                    </select>
                </div> */}
                
                <div className="input-group">
                    <span className="input-group-text" id="basic-addon2"><i className="bi bi-flag"></i></span>
                    <input className='border' type='text'  name='country'  value={country} onChange={(e)=>setCountry(e.target.value)} placeholder='Country'  required/>
                </div>
                <div className="input-group">
                    <span className="input-group-text" id="basic-addon4"><i className="bi bi-house"></i></span>
                    <input className='border' type='text'  name='city'  value={city} onChange={(e)=>setCity(e.target.value)} placeholder='city'  required/>
                </div>
                <div className="input-group">
                    <span className="input-group-text" id="basic-addon5"><i className="bi bi-signpost-2"></i></span>
                    <input className='border' type='text'  name='address'  value={address} onChange={(e)=>setAddress(e.target.value)} placeholder='Street Address' required/>
                </div>
                <div className="input-group">
                    <span className="input-group-text" id="basic-addon6"><i className="bi bi-telephone"></i></span>
                    <input className='border dwone' type='text'  name='code'  value={code} onChange={(e)=>setCode(e.target.value)} placeholder='code' required/>
                    <input className='border dtwo' type='number'  name='cell'  value={cell} onChange={(e)=>setCell(e.target.value)} placeholder='cell' required/>
                </div>
                <div className="input-group">
                    <span className="input-group-text" id="basic-addon7"><i className="bi bi-envelope-at"></i></span>
                    <input className='border' type='email'  name='companyEmail'  value={companyEmail} onChange={(e)=>setCompanyEmail(e.target.value)} placeholder='company email' required/>
                </div>
                <div className="input-group">
                    <span className="input-group-text" id="basic-addon8"><i className="bi bi-telephone"></i></span>
                    <input className='border' type='text'  name='contactDetails'  value={contactDetails} onChange={(e)=>setContactDetails(e.target.value)} placeholder='contact details' required/>
                </div>

                {loading ?<button className='btn my-btn-blue' type='submit'><FontAwesomeIcon icon={faArrowsRotate} className='spinner'/></button> : <button className='btn my-btn-blue' type='submit'>Register</button> }
            </form>
        </div>
       </div>
    </div>
  )
}

export default ProfileRegPage