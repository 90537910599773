
import DocumentTitle from '../hooks/DocumentTitle'
import VerificationComponent from '../components/approval_components/verificationComponent'


const VerificationsPage = () => {
    DocumentTitle('Verifications')


    return (
     <div className='settings-page'>
       <div className='settings-sections'>
         <VerificationComponent/>
       </div>
    </div>
  )
}

export default VerificationsPage 