import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";

const SpecialAlert = ({ message, setMessage, type }) => {
  return (
    <div className={`alert-error-c special ${type}`}>
      <div className="message-c"> {message} </div>
      <div className="al-close-btn-c" onClick={() => setMessage("")}>
        <FontAwesomeIcon icon={faCircleXmark} />
      </div>
    </div>
  );
};

export default SpecialAlert;
