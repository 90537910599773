import React from 'react'
import {DataGrid,GridToolbarColumnsButton,GridToolbarExport,GridToolbarContainer} from "@mui/x-data-grid";
import COLORS from '../../config.js/colors';

const ChangeTrackerTable = ({data}) => {
    const columns = [
        { field: "id", headerName: "ID", width: 50 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
        { field: "change_ref", headerName: "Ref", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
        { field: "title", headerName: "Constant", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
        { field: "previous_value", headerName: "Old Value", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
        { field: "new_value", headerName: "New Value", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
        { field: "previous_amount", headerName: "Old Amount", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
        { field: "new_amount", headerName: "New Amount", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
        { field: "requester_email", headerName: "Requester", width: 150, headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
        { field: "approver_email", headerName: "Approver", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
        { field: "status", headerName: "Status", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'} ,
        { field: "createdAt", headerName: "Date", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
    ];
    function getRowId(row) {
        return row.id;
      }
  

      function MyExportButton() {
        return (
          <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarExport   csvOptions={{ fileName: 'Change_Tracker'}}/>
          </GridToolbarContainer>
        );
      }


  return (
    <div className="sales-data-component">
    <DataGrid rows={data}
              getRowId={getRowId} 
              columns={columns} 
              sx={{ fontSize: 12,  width:"100%" ,fontWeight:500,"& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {backgroundColor: COLORS.headerBG},'& .super-app-theme--header': {backgroundColor: COLORS.headerBG, fontWeight:"bold",color:COLORS.hearderTXT}  }} 
              rowHeight={70} 
              checkboxSelection={true}  
              getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'} 
              slots={{toolbar:MyExportButton}}
    />
</div>
  )
}

export default ChangeTrackerTable