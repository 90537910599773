
import { Stack, Button } from "@mui/material";
import {DataGrid,GridToolbarContainer, GridToolbarColumnsButton, GridToolbarExport} from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import COLORS from "../config.js/colors";


const UsersTable = ({data}) => {
    const navigate = useNavigate()
  const columns = [
    { field: "id", headerName: "ID", width: 100 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
    { field: "first_name", headerName: "First Name", width: 200 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
    { field: "last_name", headerName: "Last Name", width: 200 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
    { field: "email", headerName: "Email", width: 200 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
    { field: "status", headerName: "Status", width: 200 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
    { field: "user_role", headerName: "Role", width: 150, headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center',valueFormatter: ({ value }) => value?.role},
    {
      field: "action",
      headerName: "Actions",
      width: 150, headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center',
      sortable: false,
      disableClickEventBubbling: true,

      renderCell: (params) => {
        
          const handleNavigateMore = (e) => {
                          const currentRow = params.row;
                           navigate(`/single_user/${currentRow.id}`);
                          return;
                        };
        return (
          <Stack direction="row" spacing={2}>
            <Button variant="text" color="primary" size="small" onClick={handleNavigateMore}>
              <FontAwesomeIcon icon={faInfoCircle} />
            </Button>
          </Stack>
        );
      },
    },
  ];
  function getRowId(row) {
    return row.id;
  }

  function MyExportButton() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarExport csvOptions={{ fileName: "user_report" }} />
      </GridToolbarContainer>
    );
  }

  return (
    <div className="sales-data-component">
          <DataGrid
            rows={data}
            getRowId={getRowId}
            columns={columns}
            slots={{toolbar:MyExportButton}}
            sx={{ fontSize: 12,  width:"100%" ,fontWeight:500,"& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {backgroundColor: COLORS.headerBG},'& .super-app-theme--header': {backgroundColor: COLORS.headerBG, fontWeight:"bold",color:COLORS.hearderTXT}  }} 
            rowHeight={70} 
            checkboxSelection={true}  
            getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'}
          />
    </div>
  );
};

export default UsersTable;
