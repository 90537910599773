import { useState, useEffect } from "react";
import axios from "../api/axios";
import Alert from "../components/alert";
import { useParams } from "react-router-dom";
import DocumentTitleNoneShared from "../hooks/DocumentTitleNoneShared";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import logo from "../img/logo.png"

const ResetPassword = () => {
  DocumentTitleNoneShared("Password Reset");
  const { id, token } = useParams();
  const RESET_URL = `/authentication/password_reset/${id}/${token}`;
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setErrorMessage("");
  }, [password, confirm_password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    setLoading(true);

    if (password !== confirm_password) {
      setErrorMessage(" The passwords do no match ");
      return;
    } else {
      
      try {
        const response = await axios.post(RESET_URL, { password });
        setSuccessMessage(response?.data?.message);
        setConfirmPassword('')
        setPassword('')
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (!err?.response) {
          setErrorMessage("No server response!");
        } else {
          setErrorMessage(err.response?.data?.message);
        }
      }
    }
  };

  return (
    <div className="reset-password-main">
        <div className='log-logo'>
            <div className='log-logo-image'>
                <img src={logo} alt='Logo'/>
            </div>
        </div>

      <form className="rp-form" onSubmit={handleSubmit}>
      {errorMessage && (  <Alert    message={errorMessage}    setMessage={setErrorMessage}    type={"error"}  />)}
      {successMessage && (  <Alert    message={successMessage}    setMessage={setSuccessMessage}    type={"success"}  />)}
        <div className="rp-header">Password Reset.</div>
        <div className="rp-sm-header">Create a new password.</div>
        <input  className="rp-input"  type="password"  name="password"  placeholder="Enter New Password"  value={password}  onChange={(e) => setPassword(e.target.value)}  required/>
        <input  className="rp-input belowc"  type="password"  name="confirm_password"  placeholder=" Confirm Password"  value={confirm_password}  onChange={(e) => setConfirmPassword(e.target.value)}  required/>
        {loading ? <button className="rp-button">  <FontAwesomeIcon icon={faArrowsRotate} className="spinner" /></button> :   <button className="rp-button">Reset</button>}
      </form>

      <div className='log-icon-container'>
             <div className='log-icons'>
                <a href="https://www.facebook.com/omnicontactbpo/" target="_blank" ><i className="bi bi-facebook"></i></a>
                <a href="https://zw.linkedin.com/company/omnicontactbpo" target="_blank" ><i className="bi bi-linkedin"></i></a>
                <a href="https://twitter.com/omnicontact_bpo" target="_blank" ><i className="bi bi-twitter-x"></i></a>
                <a href="ttps://www.youtube.com/@omnicontact5089"  target="_blank"><i className="bi bi-youtube"></i></a>
                <a href="mailto: info@omnicontact.biz"><i className="bi bi-envelope"></i></a>
             </div>
        </div>
    </div>
  );
};

export default ResetPassword;
