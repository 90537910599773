
import { useNavigate } from 'react-router-dom';
import SalesTable from '../components/salesTable';
import DocumentTitle from '../hooks/DocumentTitle';
import SalesModal from '../components/modals/salesModal';
import useAxiosFetch from '../hooks/useAxiosFetch'
import { useState, useEffect,useReducer } from 'react';
import useAuth from '../hooks/useAuth';
import ROLES from '../config.js/roles';
import Alert from '../components/alert';
import SearchByDateForm from '../components/forms/searchByDateForm';

const SalesPage = () => {
    DocumentTitle("Sales");
    const navigate = useNavigate()
    const [showModal, setShowModal] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const { auth } = useAuth();
    const [stats, setStats] = useState(null);
    const [_, forceUpdate] = useReducer((x) => x + 1, 0);


  
    const { data } = useAxiosFetch(auth?.role=== ROLES.user  ? `/webtrans/sales/${auth?.user_id}`:  `/webtrans/sales`, _);

  useEffect(() => {
    setStats(data?.data);
  }, [data]);
    const handleSale = ()=>{
        navigate('/operations')
    }


  return (
    <div className="sales-main-container">
      {errorMessage && <Alert message={errorMessage} setMessage={setErrorMessage} type={"error"}/>}
      {successMessage && <Alert message={successMessage} setMessage={setSuccessMessage} type={"success"}/>}
      <div className="sales-container">
        <div className="sales-header-cus">
          <div className='left-sect'>
          <div className="sub-header">Latest</div>
          <div className="main-header">Sales transactions</div>
          </div>
          <div className='right-sect'>
            <SearchByDateForm setStats={setStats} setErrorMessage={setErrorMessage} setSuccessMessage={setSuccessMessage}/>
          </div>
        </div>
        <div className="sales-table-container toosho">
          {stats &&  <SalesTable data={stats} setShowModal={setShowModal} setSelectedRow={setSelectedRow}/>}
        </div>
      </div>
    {showModal && <SalesModal setShowModal={setShowModal} data={selectedRow}/>}
    </div>
  );
}

export default SalesPage