import { useState, useEffect } from "react";
import axios from "../../api/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";


const RegistrationForm = ( {setErrorMessage, setSuccessMessage}) => {
    const REGISTRATION_URL = "/web/register_user";
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState(""); 
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false);
  
    useEffect(() => {
      setErrorMessage("");
    }, [first_name, last_name, email, password, confirmPassword]);
  
    const handleRegistration = async (e) => {
      e.preventDefault();
      setErrorMessage("");
      setSuccessMessage("");
      setLoading(true);
      if (password !== confirmPassword) {
        setErrorMessage(" The passwords do no match");
        setLoading(false);
        return;
      } else {
        try {
          const response = await axios.post(REGISTRATION_URL, {
            first_name,
            last_name,
            email,
            password,
          });
          setSuccessMessage(response?.data?.message);
          setLoading(false);
          setFirstName("");
          setLastName("");
          setEmail("");
          setPassword("");
          setConfirmPassword("");
        } catch (err) {
          if (!err?.response) {
            setErrorMessage("No server response!");
            setLoading(false);
          } else {
            setErrorMessage(err.response?.data?.message);
            setLoading(false);
          }
        }
      }
    };
  return (
    <form onSubmit={handleRegistration}>
      
    <div className="input-boxes">
    <div className="input-box">
        <i className="bi bi-person-bounding-box"></i>
        <input type="text" id="first_name" name="first_name" placeholder="First Name" value={first_name} onChange={(e) => setFirstName(e.target.value)} required/>
    </div>
    <div className="input-box">
        <i className="bi bi-person-bounding-box"></i>
        <input type="text" id="last_name" name="last_name"  value={last_name} onChange={(e) => setLastName(e.target.value)}  placeholder="Last name" required/>
    </div>
    <div className="input-box">
        <i className="bi bi-envelope-at"></i>
        <input type="email" id="email" name="email"  value={email} onChange={(e) => setEmail(e.target.value)}  placeholder="Email" required/>
    </div>
    <div className="input-box">
        <i className="bi bi-file-earmark-lock2"></i>
        <input type="password" id="password" name="password"   value={password} onChange={(e) => setPassword(e.target.value)} placeholder="password" required/>
    </div>
    <div className="input-box">
        <i className="bi bi-file-earmark-lock2"></i>
        <input type="password" id="confirm_password" name="confirm_password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirm password" required/>
    </div>
    <div className="button input-box">
        {loading ? <button  type="submit" ><FontAwesomeIcon icon={faArrowsRotate} className="spinner" /> </button>: <input type="submit" value="Submit"/> }
    </div>
    <div className="text sign-up-text">Already have an account? <label htmlFor="flip">Login now</label></div>
    </div>
</form>
  )
}

export default RegistrationForm