import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faGaugeHigh, faTicket, faUsers , faClipboard, faBarsStaggered} from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import ROLES from "../config.js/roles";

const MobileSideNavBar = () => {
    const{auth}= useAuth()

  const handleToggleMb = ()=>{
    let para = document.getElementById("mb-side-in1");
    para.classList.toggle("show");
  }
  const handleToggleMb2 = ()=>{
    let para = document.getElementById("mb-side-in2");
    para.classList.toggle("show");
  }


    const toggleSideBack= ()=>{
     document.getElementById("mobile-side-div").classList.remove("show-nav-bar")
     return
  }

  
  return (
      <div className="mobile-side-navbar-main-container">
      <button className='close-side-nav-btn' onClick={toggleSideBack}><i className="bi bi-x-lg"></i></button>
      <div className="side-navbar-header">Bulk Airtime</div>
      <div className="side-navbar-links-section">
        <div className='link-container'>
            <Link to={"/"} className='actual-link'> <i className="bi bi-speedometer2"></i><span>Dashboard</span></Link>
            <Link to={"/operations"} className='actual-link'><i className="bi bi-house-gear"></i>  <span>Operations</span></Link>
            <div className="link-header">History</div>
            <Link to={"/sales"} className='actual-link'><i className="bi bi-graph-up-arrow"></i> <span>Sales</span></Link>
            <div className='actual-link inner'>
              <div className="top-lin" onClick={handleToggleMb}><i className="bi bi-credit-card-2-back"></i> <span>Purchases</span></div>
              <div className="inner_side" id="mb-side-in1">
                <Link to={"/purchases"}   className="inner-side-link"><i className="bi bi-wallet2"></i> <span>Ecocash Purchases</span></Link>
                <Link to={"/bank_purchases"}   className="inner-side-link"><i className="bi bi-credit-card-2-back"></i> <span>Bank Purchases</span></Link>
                <Link to={"/manual_purchases"}   className="inner-side-link"><i className="bi bi-credit-card-2-back"></i> <span>Other Purchases</span></Link>
              </div>
            </div> 
            {/* <Link to={"/search_trans"} className='actual-link'><i className="bi bi-clipboard2-data"></i> <span>Transaction</span></Link> */}
            <div className="link-header">Reports</div>    
            <Link to={"/sales_report"} className='actual-link'><i className="bi bi-clipboard2-data"></i> <span>Sales</span></Link> 
            <div className='actual-link inner'>
              <div className="top-lin" onClick={handleToggleMb2}><i className="bi bi-clipboard2-data"></i> <span>Purchases</span></div>
              <div className="inner_side" id="mb-side-in2">
                <Link to={"/ecopurchases_report"}   className="inner-side-link"><i className="bi bi-wallet2"></i> <span>Ecocash Purchases</span></Link>
                <Link to={"/bankpurchases_report"}   className="inner-side-link"><i className="bi bi-credit-card-2-back"></i> <span>Bank Purchases</span></Link>
                <Link to={"/manpurchases_report"}   className="inner-side-link"><i className="bi bi-credit-card-2-back"></i> <span>Other Purchases</span></Link>
              </div>
            </div>   
           {  auth?.role === ROLES.admin && <Link to={"/users"} className='actual-link'><i className="bi bi-clipboard2-data"></i> <span>All Users</span></Link>}       
           {  auth?.role === ROLES.validator && <Link to={"/users"} className='actual-link'><i className="bi bi-clipboard2-data"></i> <span>All Users</span></Link>}       
        </div>   
      </div>

    </div>
  )
}

export default MobileSideNavBar