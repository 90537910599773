import React from 'react'
import {DataGrid,GridToolbarColumnsButton,GridToolbarExport,GridToolbarContainer} from "@mui/x-data-grid";
import COLORS from '../../config.js/colors';

const TicketRepTable = ({data}) => {
    const columns = [
        { field: "ticket_id", headerName: "ID", width: 50 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
        { field: "user_id", headerName: "UserID", width: 50 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
        { field: "user", headerName: "Owner", width: 200, headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center', valueFormatter: ({ value }) => value?.email},
        { field: "title", headerName: "Title", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
        { field: "description", headerName: "Description", width: 400 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
        { field: "category", headerName: "Category", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
        { field: "status", headerName: "Status", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
        { field: "createdAt", headerName: "Date", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
      ];
    
  
        function MyExportButton() {
          return (
            <GridToolbarContainer>
              <GridToolbarColumnsButton />
              <GridToolbarExport   csvOptions={{ fileName: 'ticket_report'}}/>
            </GridToolbarContainer>
          );
        }

  return (
    <div className="sales-data-component">
    <DataGrid 
    rows={data} 
    getRowId={(row) => row?.ticket_id} columns={columns} 
    slots={{toolbar:MyExportButton}}
    sx={{ fontSize: 12,  width:"100%" ,fontWeight:500,"& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {backgroundColor: COLORS.headerBG},'& .super-app-theme--header': {backgroundColor: COLORS.headerBG, fontWeight:"bold",color:COLORS.hearderTXT}  }} 
    rowHeight={70} 
    checkboxSelection={true}  
    getRowClassName={(params) =>
    params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'}
    />
</div>
  )
}

export default TicketRepTable