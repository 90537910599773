
import {DataGrid,GridToolbarContainer, GridToolbarColumnsButton, GridToolbarExport} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import COLORS from "../config.js/colors";



const PurhRepTable = ({data}) => {
    const navigate = useNavigate()
  const columns = [
    { field: "id", headerName: "ID", width: 50 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
    { field: "reference_id", headerName: "REF", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
    { field: "amount_purchased", headerName: "Amount", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
    { field: "amount_paid", headerName: "Paid", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
    { field: "mobile_number", headerName: "Mobile", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
    { field: "currency", headerName: "Currency", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
    { field: "transaction_status", headerName: "Status", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
    { field: "payment_gateway", headerName: "Gateway", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
    { field: "account_number", headerName: "Acc Number", width: 200 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
    { field: "user", headerName: "Owner", width: 200, headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center', valueFormatter: ({ value }) => value?.email },
    { field: "placed_on", headerName: "Date", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
  ];
  function getRowId(row) {
    return row.id;
  }

  function MyExportButton() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarExport csvOptions={{ fileName: "purchase_report" }} />
      </GridToolbarContainer>
    );
  }

  return (
    <div className="sales-data-component">
          <DataGrid
            rows={data}
            getRowId={getRowId}
            columns={columns}
            slots={{toolbar:MyExportButton}}
            sx={{ fontSize: 12,  width:"100%" ,fontWeight:500,"& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {backgroundColor: COLORS.headerBG},'& .super-app-theme--header': {backgroundColor: COLORS.headerBG, fontWeight:"bold",color:COLORS.hearderTXT}  }} 
            rowHeight={70} 
            // checkboxSelection={true}  
            getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'}
          />
    </div>
  );
};

export default PurhRepTable;
