import { useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const GenerateAPIClientForm = ({setSuccessMessage, setErrorMessage, setShowModal, setModalData, forceUpdate}) => {
const [name, setName] = useState('')
const [loadingToo, setLoadingToo] =useState(false)
const axiosPrivate  = useAxiosPrivate()

const handleGenerate= async(e)=>{
  e.preventDefault()
  setErrorMessage("")
  setSuccessMessage("")
  let data={
    client_name:name
  }

  try{
      const response = await axiosPrivate.post(`web/register_api`, {...data})
      setSuccessMessage(response?.data?.message)
      setName("")
      setModalData(response?.data?.data)
      setShowModal(true)
      forceUpdate()
  }catch(err){
    setErrorMessage(err.response?.data?.message)
  }
  }
  return (
    <div className='edit-user-form-container'>
 
        <form className='edit-user-form' onSubmit={handleGenerate}>

          <div className="input-group">
            <span className="input-group-text" id="basic-addon3"><i className="bi bi-file-earmark-diff"></i></span>
            <input className='border' type='text' name='name' value={name} onChange={(e)=> setName(e.target.value)} placeholder='Client Name'  required/>
          </div>

          {loadingToo ?<button className='edit-user-btn' type='submit'><FontAwesomeIcon icon={faArrowsRotate} className='spinner'/></button> : <button className='edit-user-btn' type='submit'>Generate</button> }
      
        </form>

</div>
  
  )
}

export default GenerateAPIClientForm