import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import Alert from "../alert";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useState, useEffect } from "react";

const VerifyNotificationUserModal = ({ setShowModal, forceUpdate, data }) => {
  const REMOVAL_URL = `admin/remove_notification_user/${data?.user_id}`;
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    setErrorMessage("");
  }, []);

  const handleClose = () => {
    setShowModal(false);
  };

  const handleRemoval = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    setLoading(true);

    try {
      const response = await axiosPrivate.post(REMOVAL_URL);
      setSuccessMessage(response?.data?.message);
      setLoading(false);
      forceUpdate();
    } catch (err) {
      if (!err?.response) {
        setErrorMessage("No server response!");
        setLoading(false);
      } else {
        setErrorMessage(err.response?.data?.message);
        setLoading(false);
      }
    }
  };

  return (
    <div className="inner-modal-main">
      <div className="inner-modal-body smallc">
        <button className="inner-close-modal-btn" onClick={handleClose}> <FontAwesomeIcon icon={faCircleXmark} />
        </button>
        {errorMessage && ( <Alert message={errorMessage} setMessage={setErrorMessage} type={"error"} />
        )}
        {successMessage && ( <Alert message={successMessage} setMessage={setSuccessMessage} type={"success"} />
        )}
        <div className="verification-text"> Are you sure you want to remove user whith email {data?.user?.email || "Annonymous"}</div>
        <div className="verification-buttons">
          {loading ? <button className="btn btn-danger"> <FontAwesomeIcon icon={faArrowsRotate} className="spinner" /></button>: <button className="btn btn-danger" onClick={handleRemoval}>  Delete</button>}
          <button className="btn btn-primary"onClick={(e) => {setShowModal(false)}}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default VerifyNotificationUserModal;
