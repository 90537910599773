import DocumentTitle from "../hooks/DocumentTitle";
import Loader from '../components/loader';
import SpecialAlert from '../components/specialAlert';
import useAxiosFetch from '../hooks/useAxiosFetch'
import { useState, useEffect,useReducer } from 'react';
import useAuth from '../hooks/useAuth';
import ROLES from "../config.js/roles";
import UseDetialComp from "../components/profile_components/useDetialComp";
import DealerProfileComponent from "../components/profile_components/dealerProfile_component";
import AirtimeAccounts from "../components/airtimeAccounts";
import { useParams } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import ApiClientComponet from "../components/profile_components/apiClientComponet";
import KycUploadsComponent from "../components/profile_components/kycUploadsComponent";
import ApiModal from "../components/modals/apiModal";
import MoreOptionsComponent from "../components/profile_components/moreOptionsComponent";
import DeleteProfileComponent from "../components/profile_components/deleteProfileComponent";
import VerifyDealerModal from "../components/modals/verifyDealerModal";
import ChangeRoleModal from "../components/modals/changeRoleModal";
import DealerDiscountModal from "../components/modals/dealerDiscountModal";
import DeleteAccountModal from "../components/modals/deleteAccountModal";
import LoadDealerAirtimeModal from "../components/modals/loadDealerAirtimeModal";
import EcocashPurchaseModal from "../components/modals/ecocashPurchaseModal";
import OtherPurchaseModal from "../components/modals/OtherPurchaseModal";
import DiscountCalculatorModal from "../components/modals/discountCalculatorModal";

const SingleUserPage = () => {
    DocumentTitle('Profile')
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const { auth} = useAuth();
    const [apiData, setApiData] = useState(null)
    const [showModal, setShowModal] = useState(null)
    const [showChangeRoleModal, setShowChangeRoleModal] = useState(null)
    const [showVerifyDealerModal, setShowVerifyDealerModal] = useState(false)
    const [showDealerDiscModal, setShowDealerDiscModal] = useState(false)
    const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false)
    const [showLoadAccountModal, setShowLoadAccountModal] = useState(false)
    const [showEcoPurchaseModal, setShowEcoPurchaseModal] = useState(false)
    const [showOtherPurchaseModal, setShowOtherPurchaseModal] = useState(false)
    const [showDiscountCalculatorModal, setShowDiscountCalculatorModal] = useState(false)
    const [stats, setStats] = useState(null);
    const [_, forceUpdate] = useReducer((x) => x + 1, 0);
    const{id} = useParams()
    const axiosPrivate = useAxiosPrivate()

  
    const { data} = useAxiosFetch(`/web/current_user/${id || auth?.user_id} `, _);
  
    useEffect(() => {
      setStats(data?.data);
    }, [data]);

    const handleActivate = async()=>{
        setErrorMessage("")
        setSuccessMessage("")
        setLoading(false)
        try{
          const response = await axiosPrivate.get(`/admin/activate_account/${id}`)
          setSuccessMessage(response?.data?.message)
          setLoading(false)
          forceUpdate()
      }
      catch(err){
              setErrorMessage(err?.response?.data?.message)
              setLoading(false)
          }
      }

    const handleReset = async()=>{
        setErrorMessage("")
        setSuccessMessage("")
        setLoading(false)
        try{
          const response = await axiosPrivate.get(`/admin/reset_password/${id}`)
          setSuccessMessage(response?.data?.message)
          setLoading(false)
          forceUpdate()
      }
      catch(err){
              setErrorMessage(err?.response?.data?.message)
              setLoading(false)
          }
      }
        
    
 
    const handleVerify = async()=>{
        setErrorMessage("")
        setSuccessMessage("")
        setLoading(true)
        try{
          const response = await axiosPrivate.get(`/admin/verify_account/${id}`)
          setSuccessMessage(response?.data?.message)
          setLoading(false)
          forceUpdate()
      }
      catch(err){
              setErrorMessage(err?.response?.data?.message)
              setLoading(false)
          }
      }

    

    const handleDeactivate = async()=>{
        setErrorMessage("")
        setSuccessMessage("")
        setLoading(true)
        try{
          const response = await axiosPrivate.get(`/admin/deactivate_account/${id}`)
          setSuccessMessage(response?.data?.message)
          setLoading(false)
          forceUpdate()
      }
      catch(err){
              setErrorMessage(err?.response?.data?.message)
              setLoading(false)
          }

    }



  return (
    <div className='single-user-main'>
        
        {errorMessage && <SpecialAlert message={errorMessage} setMessage={setErrorMessage} type={"error"}/>}
        {successMessage && <SpecialAlert message={successMessage} setMessage={setSuccessMessage} type={"success"}/>}
        {loading && <Loader/>}

        <div className="more-options-cont">
          <MoreOptionsComponent id={id} setErrorMessage={setErrorMessage} setSuccessMessage={setErrorMessage} setLoading={setLoading} forceUpdate={forceUpdate} setShowVerifyDealerModal={setShowVerifyDealerModal} setShowChangeRoleModal={setShowChangeRoleModal} setShowDealerDiscModal={setShowDealerDiscModal} setShowLoadAccountModal={setShowLoadAccountModal} setShowEcoPurchaseModal={setShowEcoPurchaseModal} setShowOtherPurchaseModal={setShowOtherPurchaseModal} setShowDiscountCalculatorModal={setShowDiscountCalculatorModal}/>
        </div>

        <div className='top-part'>
          <div className='left-side-cont'>
            <div className='profile-img-div'>
              {stats ? <UseDetialComp data={stats}/> :  <div className='no-data-sec'> No Details Available</div>}
            </div>
            <div className='profile-info-div'>
                {stats && stats?.company_profile ? <DealerProfileComponent data={stats?.company_profile}/> :  <div className='no-data-sec'> No Dealer Profile Available</div>}
            </div>
          </div>
        </div>


        <div className='mid-part'>
          <div className="sample-header">
            <div className="sub-header">All Accounts</div>
            <div className="main-header">Airtime Accounts</div>
          </div>
            {stats && stats?.airtime_accounts ? <AirtimeAccounts data={stats?.airtime_accounts} /> : <div className='no-data-sec'> No Accounts Available</div>}
        </div>

        <div className='mid-part-two'>
           <div className="inner-section">
            {stats && stats?.api_credential ? <ApiClientComponet data={stats?.api_credential} setApiData={setApiData} setShowModal={setShowModal} setErrorMessage={setErrorMessage} setSuccessMessage={setSuccessMessage}/> : <div className='no-data-sec'> No API Clients Available</div>}
           </div>
           <div className="inner-section">
              {stats && stats?.kyc_uploads ? <KycUploadsComponent data={stats?.kyc_uploads}/> : <div className='no-data-sec'> No Documents Available</div>}   
           </div>
        </div>

  
        <div className='bottom-part-one'>
           {stats  ? <DeleteProfileComponent data={stats} setErrorMessage={setErrorMessage} setShowDeleteAccountModal={setShowDeleteAccountModal}/> : <div className='no-data-sec'> No Profile detected</div>} 
        </div>

       {auth?.role === ROLES.admin  && 
        <div className='bottom-part'>
                <button className='admin-btn' onClick={handleActivate} >Activate</button>
                <button className='admin-btn' onClick={handleVerify} >verify</button>
                <button className='admin-btn' onClick={handleReset} >Reset</button>
                <button className='admin-btn dang' onClick={handleDeactivate} >Deactive</button>
        </div>}

        {apiData && showModal && <ApiModal setShowModal={setShowModal} data={apiData}/>}
        {stats && showVerifyDealerModal && <VerifyDealerModal setShowModal={setShowVerifyDealerModal} data={stats} forceUpdate={forceUpdate}/>}
        {stats && showChangeRoleModal && <ChangeRoleModal setShowModal={setShowChangeRoleModal} data={stats} forceUpdate={forceUpdate}/>}
        {stats && showDealerDiscModal && <DealerDiscountModal setShowModal={setShowDealerDiscModal} data={stats} forceUpdate={forceUpdate}/>}
        {stats && showDeleteAccountModal && <DeleteAccountModal setShowModal={setShowDeleteAccountModal} data={stats} forceUpdate={forceUpdate}/>}
        {stats && showLoadAccountModal && <LoadDealerAirtimeModal setShowModal={setShowLoadAccountModal} data={stats} forceUpdate={forceUpdate}/>}
        {stats && showEcoPurchaseModal && <EcocashPurchaseModal setShowModal={setShowEcoPurchaseModal} data={stats} forceUpdate={forceUpdate}/>}
        {stats && showOtherPurchaseModal && <OtherPurchaseModal setShowModal={setShowOtherPurchaseModal} data={stats} forceUpdate={forceUpdate}/>}
        {stats && showDiscountCalculatorModal && <DiscountCalculatorModal setShowModal={setShowDiscountCalculatorModal} />}

    </div>
  )
}

export default SingleUserPage