
import { Stack, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {DataGrid,GridToolbarContainer} from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTicket, faInfoCircle, faClockRotateLeft } from "@fortawesome/free-solid-svg-icons";
import useAuth from '../hooks/useAuth';
import ROLES from "../config.js/roles";
import COLORS from "../config.js/colors";


const SampleTicket = ({data, title}) => {
   const navigate = useNavigate();
   const {auth} = useAuth()


  const columns = [
    { field: "ticket_id", headerName: "ID", width: 50 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
    { field: "user", headerName: "Owner", width: 150, headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center', valueFormatter: ({ value }) => value?.email },
    { field: "title", headerName: "Title", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
    { field: "description", headerName: "Description", width: 250 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
    { field: "category", headerName: "Category", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
    { field: "status", headerName: "Status", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
    {
      field: "action",
      headerName: "Actions", 
      width: 200, 
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',  
      align: 'center',
      sortable: false,
      disableClickEventBubbling: true,

      renderCell: (params) => {
        
          const handleNavigateMore = (e) => {
                          const currentRow = params.row;
                          navigate(`/single_ticket/${currentRow.ticket_id}`);
                          return;
                        };
          const handleNavigateHistory = (e) => {
                          const currentRow = params.row;
                          navigate(`/single_ticket_history/${currentRow.ticket_id}`);
                          return;
                        };
        return (
          <Stack direction="row" spacing={2}>
            <Button variant="text" color="primary" size="small" onClick={handleNavigateMore}><FontAwesomeIcon icon={faInfoCircle} /></Button>
           {auth?.role === ROLES.admin && <Button variant="text" color="primary" size="small" onClick={handleNavigateHistory}><FontAwesomeIcon icon={faClockRotateLeft} /></Button>}
          </Stack>
        );
      },
    },
  ];
  function getRowId(row) {
    return row.ticket_id;
  }


  return (
    <div className="sample-tickets-component">
      <div className="stc-header-section">
        <FontAwesomeIcon icon={faTicket} /> <span>{title} Tickets</span>
      </div>
      {data.length > 0 && (
        <div className="stc-data-section">
          <DataGrid
            rows={data}
            getRowId={getRowId}
            columns={columns}
            sx={{ fontSize: 12,  width:"100%" ,fontWeight:500,"& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {backgroundColor: COLORS.headerBG},'& .super-app-theme--header': {backgroundColor: COLORS.headerBG, fontWeight:"bold",color:COLORS.hearderTXT}  }} 
            rowHeight={70} 
            checkboxSelection={true}  
            getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'}
          />
        </div>
      )}
      {data.length <= 0 && (
        <div className="stc-data-section">
              NO TICKETS TO DISPLAY
        </div>
      )}
    </div>
  );
};

export default SampleTicket;
