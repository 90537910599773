import { useState, useEffect } from "react";
import Alert from "../components/alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import DocumentTitleNoneShared from "../hooks/DocumentTitleNoneShared";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import logo from "../img/logo.png"

const ForgotPassword = () => {
  DocumentTitleNoneShared("Forgot Password");
  const FORGOT_URL = `/authentication/forgot_password`;
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const axiosPrivate = useAxiosPrivate()

  useEffect(() => {
    setErrorMessage("");
  }, [email]);

  const handleForgot = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    setLoading(true);
    try {
      const response = await axiosPrivate.post(FORGOT_URL, { email });
      setSuccessMessage(response?.data?.message);
      setEmail('')
      setLoading(false);
    } catch (err) {
      if (!err?.response) {
        setErrorMessage("No server response!");
        setLoading(false);
      } else {
        setErrorMessage(err?.response?.data?.message);
        setLoading(false);
      }
    }
  };
  return (
    <div className="reset-password-main">
        <div className='log-logo'>
            <div className='log-logo-image'>
                <img src={logo} alt='Logo'/>
            </div>
        </div>

      <form className="rp-form" onSubmit={handleForgot}>
        {errorMessage && (  <Alert  message={errorMessage}  setMessage={setErrorMessage}  type={"error"}/>)}
        {successMessage && (  <Alert  message={successMessage}  setMessage={setSuccessMessage}  type={"success"}/>)}
        <div className="rp-header">Password Reset.</div>
        <div className="rp-sm-header">Oops! You Forgot Your Password?</div>
        <input  className="rp-input"  type="email"  name="email"  placeholder="Please enter you email"  value={email}  onChange={(e) => setEmail(e.target.value)}  required/>
        {loading ? <button className="rp-button">  <FontAwesomeIcon icon={faArrowsRotate} className="spinner" /></button> :   <button className="rp-button">Submit</button>}
      </form>

      <div className='log-icon-container'>
             <div className='log-icons'>
                <a href="https://www.facebook.com/omnicontactbpo/" target="_blank" ><i className="bi bi-facebook"></i></a>
                <a href="https://zw.linkedin.com/company/omnicontactbpo" target="_blank" ><i className="bi bi-linkedin"></i></a>
                <a href="https://twitter.com/omnicontact_bpo" target="_blank" ><i className="bi bi-twitter-x"></i></a>
                <a href="ttps://www.youtube.com/@omnicontact5089"  target="_blank"><i className="bi bi-youtube"></i></a>
                <a href="mailto: info@omnicontact.biz"><i className="bi bi-envelope"></i></a>
             </div>
        </div>
    </div>
  );
};

export default ForgotPassword;
