import DocumentTitle from '../hooks/DocumentTitle';
import UsersTable from '../components/usersTable';
import useAxiosFetch from '../hooks/useAxiosFetch'
import { useState, useEffect,useReducer } from 'react';
import CreateUserModal from '../components/modals/createUserModal';

const AllUsersPage = () => {
    DocumentTitle("All Users");
    const [showModal, setShowModal] = useState(false)
    const [stats, setStats] = useState(null);
    const [_, forceUpdate] = useReducer((x) => x + 1, 0);
    
    const { data } = useAxiosFetch(`/admin/users`, _);
    useEffect(() => {
      setStats(data?.data);
    }, [data]);

  return (
    <div className="user-main-container">
      <div className="user-container">
        <div className="user-header">
          <div className="sub-header">All Time</div>
          <div className="main-header">Users</div>      
        </div>
        <div className='new-user-btn-div'>
          <button className='btn my-btn-blue' onClick={()=>setShowModal(true)}><i className="bi bi-person-add"> </i> New User</button>
        </div>
        <div className="user-table-container">
           {stats && <UsersTable data={stats} />}
        </div>
      </div>
      {showModal && <CreateUserModal setShowModal={setShowModal} forceUpdate={forceUpdate}/>}
    </div>
  );
}

export default AllUsersPage