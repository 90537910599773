import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Alert from "../alert";

const UpdateTransModal = ({ setShowModal, data, forceUpdate, type }) => {
  let trans_type = "ECOCASH";
  if (type === "BANK") {
    trans_type = "BANK";
  }
  const [status, setStatus] = useState("");
  const ref_id = data?.reference_id;

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const handleClose = () => {
    setShowModal(false);
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    try {
      const response = await axiosPrivate.post(
        "webtrans/update_purchase_status",
        { ref_id, status, trans_type }
      );
      setSuccessMessage(response?.data?.message);
      setStatus('')
      forceUpdate();
    } catch (err) {
      if (!err?.response) {
        setErrorMessage("No server response!");
      } else {
        setErrorMessage(err.response?.data?.message);
      }
    }
  };

  return (
    <div className="any-modal-main">
      <div className="sale-modal-body">
        <button className="sale-close-modal-btn" onClick={handleClose}><FontAwesomeIcon icon={faCircleXmark} />
        </button>
        {errorMessage && (<Alert message={errorMessage} setMessage={setErrorMessage} type={"error"}/> )}
        {successMessage && (<Alert message={successMessage} setMessage={setSuccessMessage} type={"success"}/> )}

        <div className="edit-user-form-container">
          <form className="edit-user-form" onSubmit={handleUpdate}>
            <div className="modal-heading">Update Status </div>
            <div className="input-group">
              <span className="input-group-text" id="basic-addon3"> <i className="bi bi-credit-card-2-front"></i> </span>
              <input className="border" type="number" name="amount" value={ref_id} placeholder="Ref ID" disabled />
            </div>
            <div className="input-group">
              <span className="input-group-text" id="basic-addon3"> <i className="bi bi-file-earmark-check"></i> </span>
              <select  className="border"  value={status}  onChange={(e) => setStatus(e.target.value)}  required >
                <option value="">status</option>
                <option value="FAILED">FAILED</option>
                <option value="COMPLETED">COMPLETED</option>
              </select>
            </div>

            <button type="submit" className="edit-user-btn"> Submit</button>
          </form>
        </div>

        <p className="import-txt">
          *Please note that these actions can not be reversed
        </p>
      </div>
    </div>
  );
};

export default UpdateTransModal;
