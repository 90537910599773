import logo from "../../img/logo.png"
// import "./deset.css"
import "./style.css"
import SyntaxHighlighter from 'react-syntax-highlighter';
import {atelierCaveLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import DocumentTitleNoneShared from "../../hooks/DocumentTitleNoneShared";


const Doc = () => {
  DocumentTitleNoneShared("Developer Docs");
  
  const firstCode = `{ 
      "clientid": "client id", 
      "clientsecret": "client secret", 
      "apikey": "apikey", 
      "Content-Type": "application/json"
}`

const secondCode =`{
      "success": true,
      "message": "Airtime Account Details",
      "detials": [
          {
              "accountNumber": "EX-ACC-#",
              "status": "ACTIVE",
              "currency": "USD",
              "balance": "233.8"
          },
          {
              "accountNumber": "EX-ACC-#-2",
              "status": "ACTIVE",
              "currency": "ZIG",
              "balance": "45"
          }
      ]
}`

const forthCode =`{
      "success": true,
      "message": "you have successfully purchased USD1.00 airtime for 263777888999",
      "details": {
          "referenceId": "REF-ID",
          "accountNumber": "EX-ACC-#",
          "currency": "USD",
          "amount": 1.00,
          "reciever": "263777888999",
          "status": "COMPLETED",
          "channel": "API",
          "time": "2023-05-27T09:26:06.581Z"
      }
}`


const thirdCode =`{
    "amount": 1.00,
    "currency": "USD",
    "reciever_number": "263777888999"
}`
  return (
     
<div  className="doc-container" >
    <div className="header-navbar">
        <div className="nav-container">
            <div className="logo-container">
                <img src={logo} alt="omnicontact logo"/>
            </div>
            <div className="doc-link-container">
                <div className="doc-actual-link"><a href="#">About Us</a></div>
                <div className="doc-actual-link"><a target="_blank" href="https://bulkairtime.omnicontact.biz">sign In</a></div>
            </div>
        </div>
    </div>
    

    <div className="main-container">
        <div className="left-section">
            <div className="side-link-header">
                Api Documentation
            </div>

            <div className="side-link-container">
                <div className="actual-side-link"><a href="#overview">Overview</a> </div>
                <div className="actual-side-link"><a href="#getting-started">Getting Started</a> </div>
                <div className="actual-side-link"><a href="#authentication">Authentication</a> </div>
                <div className="actual-side-link"><a href="#accounts">Accounts</a> </div>
                <div className="actual-side-link"><a href="#transactions">Transactions</a> </div>
                <div className="actual-side-link"><a href="#status-codes">Status Codes</a> </div>
            </div>
        </div>




        <div className="doc-right-section">
            <div className="doc-section" id="overview">
                <div className="doc-section-header">Overview</div>
                <div className="section-description">
                    Bulk Airtime Distribution is an online platform for partners to purchase airtime in bulk at a
                    discounted price. The portal offers various methods of payment to make life easier for partners. 
                    Partners have an option to sell the airtime to individuals straight from the portal. The portal offers comprehensive reports to track transaction, productivity and perfomance . 
                    Bulk Airtime Distribution APIs offer an easier way for partners to create custom intergrations.
                </div>
            </div>

           
            <div className="doc-section" id="getting-started">
                <div className="doc-section-header">Get Started</div>
                <div className="section-description">
                    Intergrating with the APIs is quite easy, but note that these APIs are meant for already registered partners. You can <a href="https://bulkairtime.omnicontact.biz" target="_blank" className="ext-link">click here</a> to register as partner and go through all the required verifications.
                </div>
                <div className="section-description">
                    You need to have a registerd API client. To register an API client you need to log into the portal then <span className="direction-url">menu&gt;edit details&gt;api client</span>.
                    register a client and give it a unique name. After registering your client you will receive credentials. 
                </div>

                <div className="section-description">
                    The base URL for all API requests
                </div>
                <div className="url-div">
                    <div className="method">METHOD</div>
                    <div className="url">https://bulkairtime.omnicontact.biz/api/v1/obad/gateway/</div>
                </div>

            </div>


            <div className="doc-section" id="authentication">
                <div className="doc-section-header">Authentication</div>
                <div className="section-description">
                    Bulk Airtime Distribution uses a variety of client credentials to authenticate all API requests. These credentials should be part of the request headers.
                </div>

                <div className="section-description-example"> Example Request Headers.</div>

                <div className="code-section">
                    <SyntaxHighlighter language="javascript" style={atelierCaveLight} showLineNumbers >
                        {firstCode}
                    </SyntaxHighlighter>
                </div>

                <div className="explanation-section">
                    <table className="table table-striped table-hover table table-bordered">
                        <thead className="table-primary">
                            <tr>
                            <th scope="col" className="col-2 py-3">Header</th>
                            <th scope="col" className="col-8 py-3">Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td className="py-5">clientid</td>
                            <td className="py-5">This is the unique ID of the API client that you have registered.</td>
                            </tr>
                            <tr>
                            <td className="py-5">clientsecret</td>
                            <td className="py-5">This is the unique client secret for your API client</td>
                            </tr>
                            <tr>
                            <td className="py-5">apikey</td>
                            <td className="py-5">This is the unique key for your API client</td>
                            </tr>
                            <tr>
                            <td className="py-5">Content-Type</td>
                            <td className="py-5">This identifies the type of data being sent and the API expects JSON data</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
               
            </div>


            
            <div className="doc-section" id="accounts">
                <div className="doc-section-header">Accounts</div>
                <div className="section-description">
                    This API endpoint can be used to query for a partner's airtime account details. It returns a JSON response object.
                </div>
                <div className="url-div">
                    <div className="method">GET</div>
                    <div className="url">/accounts</div>
                </div>
                <div className="section-description-example">
                    Example Response
                </div>
                <div className="code-section">
                <SyntaxHighlighter language="javascript" style={atelierCaveLight} showLineNumbers >
                        {secondCode}
                    </SyntaxHighlighter>
                </div>

                <div className="explanation-section">
                    <table className="table table-striped table-hover table table-bordered">
                        <thead className="table-primary">
                          <tr>
                            <th scope="col" className="col-2 py-3">Attribute</th>
                            <th scope="col" className="col-8 py-3">Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="py-5">success</td>
                            <td className="py-5">The status of the request. Returns either a true or false.</td>
                          </tr>
                          <tr>
                            <td className="py-5">message</td>
                            <td className="py-5">This contains the basic description of the API response details.</td>
                          </tr>
                          <tr>
                            <td className="py-5">details</td>
                            <td className="py-5">This is an array containing all the airtime accounts registered by the partner.</td>
                          </tr>
                          <tr>
                            <td className="py-5">accountNumber</td>
                            <td className="py-5">This is a unique identifier of an airtime account.</td>
                          </tr>
                          <tr>
                            <td className="py-5">status</td>
                            <td className="py-5">This contains the current status of an airtime account. It can contain either ACTIVE/DEACTIVATED</td>
                          </tr>
                          <tr>
                            <td className="py-5">currency</td>
                            <td className="py-5">This describes the currency that is configured for an airtime account.</td>
                          </tr>
                          <tr>
                            <td className="py-5">balance</td>
                            <td className="py-5">This describes the current balance of an airtime account.</td>
                          </tr>
                        </tbody>
                      </table>
                </div>

            </div>


            <div className="doc-section" id="transactions">
                <div className="doc-section-header">Transactions</div>
                <div className="section-description">This API endpoint can be used to conduct transaction from other applications or programs.</div>
                <div className="doc-sub-header">Airtime Sale</div>
                <div className="section-description">This API endpoint can be used sell airtime from third party systems.</div>
                <div className="url-div">
                    <div className="method">POST</div>
                    <div className="url">/sell</div>
                </div>

                <div className="section-description-example">
                    Example Request Body
                </div>

                <div className="code-section">
                <SyntaxHighlighter language="javascript" style={atelierCaveLight} showLineNumbers >
                        {thirdCode}
                    </SyntaxHighlighter>
                </div>

                <div className="explanation-section">
                    <table className="table table-striped table-hover table table-bordered">
                        <thead className="table-primary">
                          <tr>
                            <th scope="col" className="col-2 py-3">Attribute</th>
                            <th scope="col" className="col-8 py-3">Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="py-5">amount</td>
                            <td className="py-5">This is the amount of airtime to be sold.</td>
                          </tr>
                          <tr>
                            <td className="py-5">currency</td>
                            <td className="py-5">This is the currency of the airtime to be sold. There should be an airtime account configured with this currency.</td>
                          </tr>
                          <tr>
                            <td className="py-5">reciever_number</td>
                            <td className="py-5">This is the mobile number that is to recieve the airtime after the transaction completes.</td>
                          </tr>
                        </tbody>
                      </table>
                </div>

                <div className="section-description-example">
                    Example Response
                </div>

                <div className="code-section">
                <SyntaxHighlighter language="javascript" style={atelierCaveLight} showLineNumbers >
                        {forthCode}
                    </SyntaxHighlighter>
                </div>

                <div className="explanation-section">
                    <table className="table table-striped table-hover table table-bordered">
                        <thead className="table-primary">
                          <tr>
                            <th scope="col" className="col-2 py-3">Attribute</th>
                            <th scope="col" className="col-8 py-3">Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="py-5">success</td>
                            <td className="py-5">This is the status of the transaction</td>
                          </tr>
                          <tr>
                            <td className="py-5">message</td>
                            <td className="py-5">This gives a brief description of the entire transaction </td>
                          </tr>
                          <tr>
                            <td className="py-5">details</td>
                            <td className="py-5">this contains all the relevent information about the transaction</td>
                          </tr>
                          <tr>
                            <td className="py-5">referenceId</td>
                            <td className="py-5">This is a unique identifier of that transaction</td>
                          </tr>
                          <tr>
                            <td className="py-5">accountNumber</td>
                            <td className="py-5">This is the unique identifier of the airtime account that was affected by the transaction </td>
                          </tr>
                          <tr>
                            <td className="py-5">currency</td>
                            <td className="py-5">This is the currency used for the transaction</td>
                          </tr>
                          <tr>
                            <td className="py-5">amount</td>
                            <td className="py-5">This is the amount of airtime sold.</td>
                          </tr>
                          <tr>
                            <td className="py-5">reciever</td>
                            <td className="py-5">This is the mobile number where the airtime was transfered to.</td>
                          </tr>
                          <tr>
                            <td className="py-5">status</td>
                            <td className="py-5">This describes the current status of the transaction.</td>
                          </tr>
                          <tr>
                            <td className="py-5">channel</td>
                            <td className="py-5">This is the channel that was used to conduct the transaction.</td>
                          </tr>
                          <tr>
                            <td className="py-5">time</td>
                            <td className="py-5">This is the time when the transaction took place.</td>
                          </tr>
                        </tbody>
                      </table>
                </div>
            


            </div>


           
            <div className="doc-section" id="status-codes">
                <div className="doc-section-header">Status Codes</div>
                <div className="section-description">The API uses standard HTTP Status codes and below are the statuses used by the API.</div>

                <div className="explanation-section">
                    <table className="table table-striped table-hover table table-bordered">
                        <thead className="table-primary">
                          <tr>
                            <th scope="col" className="col-2 py-3">Status</th>
                            <th scope="col" className="col-8 py-3">Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="py-5">200</td>
                            <td className="py-5">OK - this means the request was successful.</td>
                          </tr>
                          <tr>
                            <td className="py-5">400</td>
                            <td className="py-5">BAD REQUEST - this means that the request body you sent was not complete or correct.</td>
                          </tr>
                          <tr>
                            <td className="py-5">401</td>
                            <td className="py-5">UNAUTHORIZED - this means that the request did not include the required credentials for authentication.</td>
                          </tr>
                          <tr>
                            <td className="py-5">500</td>
                            <td className="py-5">INTERNAL SERVER ERROR - this means that something went wrong on the API's side.</td>
                          </tr>
                        </tbody>
                      </table>
                </div>
            
            </div>



        </div>
    </div>

</div>

  )
}

export default Doc