import React from 'react'
import {DataGrid,GridToolbarColumnsButton,GridToolbarExport,GridToolbarContainer} from "@mui/x-data-grid";
import COLORS from '../../config.js/colors';

const VerificationsTables = ({data}) => {
    const columns = [
        { field: "id", headerName: "ID", width: 100 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
        { field: "name", headerName: "Name", width: 200, headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center' ,valueFormatter: ({ value }) => value?.name},
        { field: "owner", headerName: "Owner", width: 200, headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center', valueFormatter: ({ value }) => value?.email },
        { field: "decision", headerName: "Decision", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
        { field: "approver", headerName: "Verified By", width: 200, headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center', valueFormatter: ({ value }) => value?.email},
        { field: "reason", headerName: "Reason", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
        { field: "createdAt", headerName: "Date", width: 150 , headerClassName: 'super-app-theme--header',headerAlign: 'center',  align: 'center'},
    ];
    function getRowId(row) {
        return row.id;
      }
  
 
      function MyExportButton() {
        return (
          <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarExport   csvOptions={{ fileName: 'profile_verifications'}}/>
          </GridToolbarContainer>
        );
      }
  return (
    <div className="sales-data-component">
    <DataGrid 
        rows={data} 
        getRowId={getRowId} 
        columns={columns} 
        slots={{toolbar:MyExportButton}}
        sx={{ fontSize: 12,  width:"100%" ,fontWeight:500,"& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {backgroundColor: COLORS.headerBG},'& .super-app-theme--header': {backgroundColor: COLORS.headerBG, fontWeight:"bold",color:COLORS.hearderTXT}  }} 
        rowHeight={70} 
        checkboxSelection={true}  
        getRowClassName={(params) =>
        params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'}
        />
</div>
  )
}

export default VerificationsTables