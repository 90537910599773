
import DocumentTitle from '../hooks/DocumentTitle'
import ApprovalComponent from '../components/approval_components/approvalComponent';


const ApprovalsPage = () => {
    DocumentTitle('Approvals')


    return (
     <div className='settings-page'>
       <div className='settings-sections'>
         <ApprovalComponent/>
       </div>
    </div>
  )
}

export default ApprovalsPage