import {useEffect,useState, useReducer} from 'react'
import useAxiosFetch from '../../hooks/useAxiosFetch';
import TicketRepTable from '../../components/tables/ticketRepTable';
import DocumentTitle from '../../hooks/DocumentTitle';


const TicketReport = () => {
  DocumentTitle("Tickets Report");
  const [stats, setStats] = useState([]);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const { data } = useAxiosFetch("/ticket/all_tickets",_);

  useEffect(() => {
      setStats(data?.data);
    }, [data]);
        

  return (

    <div className="user-main-container">
      <div className="user-container">
        <div className="user-header">
          <div className="sub-header">All Time</div>
          <div className="main-header">Tickets Report</div>      
        </div>
        <div className="user-table-container rep">
        {stats && <TicketRepTable data={stats}/>}
        </div>
      </div>
    </div>
  );
}

export default TicketReport