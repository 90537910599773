import { useState, } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import useAuth from "../hooks/useAuth";
import { Link } from "react-router-dom";
import ROLES from '../config.js/roles';
import useAxiosPrivate from "../hooks/useAxiosPrivate"

 
const NewTopNavComponent = () => {
    const navigate = useNavigate()
    const [search, setSearch] = useState('')
    const {auth, setAuth} = useAuth()

    const axiosPrivate = useAxiosPrivate()
    const toggleSideShow = () => {
          document.getElementById("mobile-side-div").classList.add("show-nav-bar");
          return;
        };

    const handleProfile=()=>{
        navigate('/profile')
    }

    const handleLogout=async()=>{
        try{
            const response = await axiosPrivate.get("/authentication/logout")
            setAuth({email: "", role: "", user_id:"", accessToken: ""})
            navigate("/login", {replace : true})
       }
       catch(err){
            console.log(err) 
       }
    }

  return (
    <div className='top-nav-main'>
        <div className='left-section'>
            <div className='toggle-side-nav' onClick={toggleSideShow}><i className="bi bi-text-left"></i></div>
            <div  className='page-heading'><i className="bi bi-house"></i> / <span id='nav-title'>OOBAD</span></div>
        </div>
        <div className='right-section'>
            <form className='search-form'>
                <div className="input-group mb-3">
                <span className="input-group-text" id="search-button"><FontAwesomeIcon icon={faMagnifyingGlass}/></span>
                <input type='text' className="form-control" name='search' value={search} onChange={(e)=>setSearch(e.target.value)}  placeholder='Search...' aria-describedby="search-button" required />
                </div>
            </form>
            <div className="icon-div " ><i className="bi bi-bell-fill"></i></div>
            <div className="icon-div " >
                <i className="bi bi-list-ul "></i>
                <div className="inner-section">
                {auth?.role === ROLES.user &&    <div className='actual-top-link'> <Link to={"/dealer_profile"} ><i className="bi bi-person"></i><span>Dealer Registration</span></Link></div>}
                {auth?.role === ROLES.user &&    <div className='actual-top-link'><Link to={"/kyc_uploads"} ><i className="bi bi-cloud-arrow-up"></i><span>Upload Documents</span></Link></div>}
                    <div className='actual-top-link'><Link to={"/edit_details"} > <i className="bi bi-pen"></i><span>Edit details</span></Link></div>
                    <div className='actual-top-link'><Link to={"/tickets_page"} > <i className="bi bi-ticket-perforated"></i><span>Tickets</span></Link></div>
                    <div  className='actual-top-link'><Link to={"/developer/docs"} target='blank'><i className="bi bi-code-slash"></i><span>Developer Docs</span></Link></div>  
                {auth?.role !== ROLES.user &&  <div className='actual-top-link'><Link to={"/settings"} > <i className="bi bi-gear"></i><span>Settings</span></Link></div>}
                {auth?.role !== ROLES.user &&  <div className='actual-top-link'><Link to={"/verifications"} > <i className="bi bi-person-check"></i><span>Verifications</span></Link></div>}
                {auth?.role !== ROLES.user &&  <div  className='actual-top-link '><Link to={"/approvals"}> <i className="bi bi-check2-circle"></i><span>Approvals</span></Link></div>}
                {auth?.role !== ROLES.user &&     <div  className='actual-top-link'><Link to={""}>
                            <i className="bi bi-clipboard2-data"></i><span> Reports</span></Link>
                            <div className="sub-inner">
                                <div className="sub-inner-link"><Link to={"/change_trail"}> <i className="bi bi-clipboard2-data"></i><span>Change trail</span></Link></div>
                                <div className="sub-inner-link"><Link to={"/tickets_report"}> <i className="bi bi-clipboard2-data"></i><span>Tickets</span></Link></div>
                                <div className="sub-inner-link"><Link to={"/user_discounts"}> <i className="bi bi-clipboard2-data"></i><span>Discounts</span></Link></div>
                                <div className="sub-inner-link"><Link to={"/verifications_report"}> <i className="bi bi-clipboard2-data"></i><span>Verifications</span></Link></div>
                            </div>
                    </div>  }
                    <hr className="actual-top-devider"/>
                    <div className='actual-top-link'> <Link to={"/profile"} ><i className="bi bi-person"></i><span>Profile</span></Link></div>
                    <div className='actual-top-link ' onClick={handleLogout}><i className="bi bi-box-arrow-right"></i> <span>logout</span> </div>
                </div>
            </div>

            <div className='username-div'>
                <div className='username-txt'>{auth?.email ||"Annonymous"} |</div>
                <div className='profile-img' onClick={handleProfile}></div>
            </div>
        </div>
    </div>
  )
} 

export default NewTopNavComponent