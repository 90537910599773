import React from 'react'
import { useState, useEffect, useReducer} from 'react'
import UpdateTransModal from '../components/modals/updateTransModal'
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import Alert from "../components/alert";
import useAxiosFetch from '../hooks/useAxiosFetch'
import { useParams } from "react-router-dom";
import useAuth from '../hooks/useAuth'
import ROLES from '../config.js/roles'
import EcoVerificationModal from '../components/modals/ecoVerificationModal'

const SearchTrans = () => {
    const {id} = useParams();
    const {auth}= useAuth()

    const [ref_id, setRefId] = useState("")
    
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const axiosPrivate = useAxiosPrivate()

    const [stats, setStats] = useState(null);
    const [veriData, setVeriData] = useState(null);
    const [showModal, setShowModal] = useState(false)
    const [showVeriModal, setShowVeriModal] = useState(false)
    const [_, forceUpdate] = useReducer((x) => x + 1, 0);
    const { data} = useAxiosFetch(`/rep/purchase/${id}`, _);

    useEffect(() => {
      setStats(data?.data);
      setRefId(data?.data?.reference_id)
    }, [data]);
 
 

    const handleVerify = async()=>{
        setLoading(true)
        setErrorMessage("")
        console.log(ref_id)
  
        try{
            const response = await axiosPrivate.post('/webtrans/transaction_status', {ref_id})
            setVeriData(response?.data?.data)
            setShowVeriModal(true)
            forceUpdate()
            setLoading(false)
        }catch(err){
          setErrorMessage(err?.response?.data?.message)
          setLoading(false)
        }
    }

  return (
    <div className='search-trans-main'>
        {errorMessage && (<Alert message={errorMessage} setMessage={setErrorMessage} type={"error"}/> )}
        <div className='st-midpart'>
            <div className='with-data'>
                <div className='info-strip'>
                        <div className='user-info'>
                            <div className='info-header'>Full Name</div>
                            <div className='info-details'>{stats?.user?.first_name} {stats?.user?.last_name}</div>
                        </div>
                        <div className='user-info'>
                            <div className='info-header'>Email</div>
                            <div className='info-details'>{stats?.user?.email}</div>
                        </div>
                        <div className='user-info'>
                            <div className='info-header'>Account #</div>
                            <div className='info-details'>{stats?.account_number}</div>
                        </div>
                </div>
                <div className='info-strip'>
                        <div className='user-info'>
                            <div className='info-header'>Ref ID</div>
                            <div className='info-details'>{stats?.reference_id}</div>
                        </div>
                        <div className='user-info'>
                            <div className='info-header'>Currency</div>
                            <div className='info-details'>{stats?.currency}</div>
                        </div>
                        <div className='user-info'>
                            <div className='info-header'>Status</div>
                            <div className='info-details'>{stats?.transaction_status}</div>
                        </div>

                </div>
                <div className='info-strip'>
                        <div className='user-info'>
                            <div className='info-header'>Mobile</div>
                            <div className='info-details'>{stats?.mobile_number}</div>
                        </div>

                        <div className='user-info'>
                            <div className='info-header'>Channel</div>
                            <div className='info-details'>{stats?.channel}</div>
                        </div>
                        <div className='user-info'>
                            <div className='info-header'>Gateway</div>
                            <div className='info-details'>{stats?.payment_gateway}</div>
                        </div>
                </div>
                <div className='info-strip'>

                        <div className='user-info'>
                            <div className='info-header'>Ammount Purchased</div>
                            <div className='info-details numc'>{stats?.amount_purchased}</div>
                        </div>
                        <div className='user-info'>
                            <div className='info-header'>Ammount Paid</div>
                            <div className='info-details numc'>{stats?.amount_paid}</div>
                        </div>
                </div>
            </div>
            {/* <div className='no-data'>NO DATA</div> */}
        </div>
       {auth?.role !== ROLES.user && <div className='st-bottompart'>
                <button className='trans-btn' onClick={handleVerify} >Verify</button>
                <button className='trans-btn' onClick={()=>setShowModal(true)} >Update</button>
        </div>}

        {showModal && stats && <UpdateTransModal setShowModal={setShowModal} data={stats} forceUpdate={forceUpdate} type={"ECO"}/>}
        {showVeriModal && veriData && <EcoVerificationModal setShowModal={setShowVeriModal} data={veriData} />}
    </div>
  )
}

export default SearchTrans