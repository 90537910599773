import React from 'react'
import { useState } from 'react';
import LoginForm from '../components/forms/loginForm'
import RegistrationForm from '../components/forms/registrationForm';
import Alert from "../components/alert";
import logo from "../img/logo.png"
import illust from "../img/background.jpg"
import DocumentTitleNoneShared from "../hooks/DocumentTitleNoneShared";

const LogRegPage = () => {
    DocumentTitleNoneShared('Bulk Airtime')
    const [errorMessage, setErrorMessage] = useState("");
    const [logerrorMessage, setLogErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
 
 
  return (
    <div className='log-reg-page'>
        <div className='log-logo'>
            <div className='log-logo-image'>
                <img src={logo} alt='Logo'/>
            </div>
        </div>
        <div className="container">
            <input type="checkbox" id="flip"/>
            <div className="cover">
                <div className="front">
                <img src="" alt=""/>
                <div className="text">
                    <span className="text-1">Welcome back</span>
                    <span className="text-2">Login with your personal details</span>
                    <span className="text-2">to get started</span>
                </div> 
                </div>
                <div className="back">
                <img className="backImg" src="" alt=""/>
                <div className="text">
                    <span className="text-1">Hey There,</span>
                    <span className="text-2">To start this journey with us,</span>
                    <span className="text-2">register an account.</span>
                </div>
                </div>
            </div>

            <div className="forms">
                <div className="form-content">
                    <div className="login-form">
                        {logerrorMessage &&  <Alert message={logerrorMessage} setMessage={setLogErrorMessage} type={"error"} />}
                        <div className="title">Log In</div>
                        <LoginForm setErrorMessage={setLogErrorMessage}/>
                    </div>
                    
                    <div className="signup-form">
                        {errorMessage &&  <Alert message={errorMessage} setMessage={setErrorMessage} type={"error"} />}
                        {successMessage &&  <Alert message={successMessage} setMessage={setSuccessMessage} type={"success"} />}
                        <div className="title">Sign Up</div>
                        <RegistrationForm setErrorMessage={setErrorMessage} setSuccessMessage={setSuccessMessage}/>
                    </div>
                 </div>
            </div>
        </div>

        <div className='log-icon-container'>
             <div className='log-icons'>
                <a href="https://www.facebook.com/omnicontactbpo/" target="_blank" ><i className="bi bi-facebook"></i></a>
                <a href="https://zw.linkedin.com/company/omnicontactbpo" target="_blank" ><i className="bi bi-linkedin"></i></a>
                <a href="https://twitter.com/omnicontact_bpo" target="_blank" ><i className="bi bi-twitter-x"></i></a>
                <a href="ttps://www.youtube.com/@omnicontact5089"  target="_blank"><i className="bi bi-youtube"></i></a>
                <a href="mailto: info@omnicontact.biz"><i className="bi bi-envelope"></i></a>
             </div>
        </div>

        <div className='illus-container'>
            <img src={illust} alt=''/>
        </div>
        <div className='top-circle'> </div>
    </div>
  )
}

export default LogRegPage