import { BrowserRouter, Routes, Route } from "react-router-dom";
import SharedIndexLayout from "./pages/sharedIndexLayout";
import MainDashboard from "./pages/mainDashboard";
import ResetPassword from "./pages/resetPassword";
import ForgotPassword from "./pages/forgotPassword";
import VerifyEmail from "./pages/verifyEmail";
import OperationsPage from "./pages/operationsPage";
import SalesPage from "./pages/salesPage";
import PurchasesPage from "./pages/purchasesPage";
import SingleUserPage from "./pages/singleUserPage";
import ProfilePage from "./pages/profilePage";
import SettingsPage from "./pages/settingsPage";
import AllUsersPage from "./pages/allUsers";
import SearchTrans from "./pages/searchTrans.js";
import RequireAuth from "./components/requireAuth.js";
import { UserProvider } from "./contexts/userContext.js";
import Salesreport from "./pages/reports/salesreport.js";
import DiscountsPage from "./pages/discountsPage.js";
import ApprovalsPage from "./pages/approvals.js";
import VerificationsPage from "./pages/verificationsPage.js";
import TicketsPage from "./pages/myTickets.js";
import TicketReport from "./pages/reports/ticketReport.js";
import SingleTicketPage from "./pages/singleTicketPage.js";
import SingleTicketHistory from "./pages/singleTicketHistory.js";
import ProfileRegPage from "./pages/profileRegPage.js";
import KycUploadPage from "./pages/uploadKYCpage.js";
import ROLES from "./config.js/roles.js";
import SingleBank from "./pages/singleBank.js";
import SingleManual from "./pages/singleManual.js";
import BankPurchasePage from "./pages/BankPurchasesPage.js";
import ManualPurchasesPage from "./pages/manualPurchasesPage.js";
import LogRegPage from "./pages/logRegPage.js";
import ChangeTrackerReport from "./pages/reports/changeTracker.js";
import UserDiscountsReport from "./pages/reports/userDiscountsReport.js";
import VerificationsReport from "./pages/reports/verificationsReport.js";
import EcocashPurchaseReport from "./pages/reports/ecopurchasesReport.js";
import BankPurchasesReport from "./pages/reports/bankpurchaseReport.js";
import ManualPurchasesReport from "./pages/reports/manualpurchasesReport.js";
import PageNotFound from "./pages/404.js";
import Doc from "./pages/docs/doc.js";




function App() {
  return (
    <UserProvider>
          <BrowserRouter>
            <Routes>

                  <Route element={<RequireAuth allowedRoles={[ROLES.user, ROLES.agent,ROLES.data_capture,ROLES.validator,ROLES.admin]}/>}>      
                      <Route path="/" element={<SharedIndexLayout/>}>
                          <Route index element={<MainDashboard/>}/>
                          <Route path="/operations" element={<OperationsPage/>}/>
                          <Route path="/sales" element={<SalesPage/>}/>
                          <Route path="/purchases" element={<PurchasesPage/>}/>
                          <Route path="/manual_purchases" element={<ManualPurchasesPage/>}/>
                          <Route path="/bank_purchases" element={<BankPurchasePage/>}/>
                          <Route path="/profile" element={<SingleUserPage/>}/>
                          <Route path="/edit_details" element={<ProfilePage/>}/>
                          <Route path="/single_trans/:id" element={<SearchTrans/>}/>
                          <Route path="/single_bank/:id" element={<SingleBank/>}/>
                          <Route path="/single_manual/:id" element={<SingleManual/>}/>
                          <Route path="/sales_report" element={<Salesreport/>}/>
                          <Route path="/ecopurchases_report" element={<EcocashPurchaseReport/>}/>
                          <Route path="/bankpurchases_report" element={<BankPurchasesReport/>}/>
                          <Route path="/manpurchases_report" element={<ManualPurchasesReport/>}/>
                          <Route path="/tickets_page" element={<TicketsPage/>}/>
                          <Route path="/single_ticket/:id" element={<SingleTicketPage/>}/>
                          <Route path="dealer_profile" element={<ProfileRegPage/>}/>
                          <Route path="kyc_uploads" element={<KycUploadPage/>}/>
                          

                          <Route element={<RequireAuth allowedRoles={[ROLES.agent,ROLES.data_capture,ROLES.validator,ROLES.admin]}/>}>   
                            <Route path="/single_user/:id" element={<SingleUserPage/>}/>
                            <Route path="/users" element={<AllUsersPage/>}/>
                            <Route path="/tickets_report" element={<TicketReport/>}/>


                              
                          </Route>

                          <Route element={<RequireAuth allowedRoles={[ROLES.data_capture,ROLES.validator,ROLES.admin]}/>}>
                                  <Route path="change_trail" element={<ChangeTrackerReport/>}/>
                                  <Route path="user_discounts" element={<UserDiscountsReport/>}/>
                                  <Route path="verifications_report" element={<VerificationsReport/>}/>      
                          </Route>

                          <Route element={<RequireAuth allowedRoles={[ROLES.validator,ROLES.admin]}/>}> 
                            <Route path="/settings" element={<SettingsPage/>}/> 
                            <Route path="/discounts" element={<DiscountsPage/>}/>
                            <Route path="/approvals" element={<ApprovalsPage/>}/>
                            <Route path="/verifications" element={<VerificationsPage/>}/>
                            <Route path="single_ticket_history/:id" element={<SingleTicketHistory/>}/>
                          </Route>
                                                    
                      </Route>
                  </Route> 
                     
                <Route path="/login" element={<LogRegPage/>}/>
                <Route path="/password_reset/:id/:token" element={<ResetPassword/>}/>
                <Route path="/email_verification/:id/:token" element={<VerifyEmail/>}/>
                <Route path="/forgot_password" element={<ForgotPassword/>}/>
                <Route path="/developer/docs" element={<Doc/>}/>
                <Route path="*" element={<PageNotFound/>}/>
            </Routes>
          </BrowserRouter> 
  </UserProvider>
  )
}

export default App;
