import React from 'react'

const UseDetialComp = ({data}) => {
  return (
    <>
        <div className='user-img'>
            <div className='actual-img'></div>
        </div>
        <div className='user-img-info'>
            <p className='user-name'>{data?.first_name} {data?.last_name}</p>
            <p className='user-desc text-primary'>{data?.email}</p>
            <p className='user-name text-danger'>{data?.status} {data?.is_verified.toUpperCase()}</p>

        </div>
    </>
  )
}

export default UseDetialComp