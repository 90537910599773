import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import Alert from "../alert";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useState, useEffect } from "react";
import CURRENCY from "../../config.js/currency";

const DealerDiscountModal = ({ setShowModal, forceUpdate, data }) => {
  const VERIFICATION_URL = `cons/custom_discount/${data?.id}`;
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState("");
  const options = CURRENCY;
  const [currency, setCurrency] = useState("");
  const [amount, setAmount] = useState("");
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    setErrorMessage("");
  }, []);

  const handleClose = () => {
    setShowModal(false);
  };

  const handleSetDiscount = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    setLoading(true);
    const sdata = {
      currency: currency,
      amount: amount,
    };

    try {
      const response = await axiosPrivate.post(VERIFICATION_URL, { ...sdata });
      setSuccessMessage(response?.data?.message);
      setLoading(false);
      forceUpdate();
    } catch (err) {
      if (!err?.response) {
        setErrorMessage("No server response!");
        setLoading(false);
      } else {
        setErrorMessage(err.response?.data?.message);
        setLoading(false);
      }
    }
  };
  return (
    <div className="inner-modal-main">
      <div className="inner-modal-body smallc">
        <button className="inner-close-modal-btn" onClick={handleClose}>
          <FontAwesomeIcon icon={faCircleXmark} />
        </button>
        {errorMessage && ( <Alert message={errorMessage} setMessage={setErrorMessage} type={"error"}/>)}
        {successMessage && ( <Alert message={successMessage} setMessage={setSuccessMessage} type={"success"}/>)}
        <div className="edit-user-form-container">
          <form className="edit-user-form" onSubmit={handleSetDiscount}>
            <div className="modal-heading">Set Custom Discount for {data?.company_profile?.name || "Anonymous"}</div>
            <div className="input-group">
              <span className="input-group-text" id="basic-addon3"> <i className="bi bi-currency-dollar"></i> </span>
              <input className="border" type="number" name="amount" value={amount} onChange={(e) => setAmount(e.target.value)} placeholder="Amount" required/>
            </div>
            <div className="input-group">
              <span className="input-group-text" id="basic-addon3"> <i className="bi bi-currency-exchange"></i> </span>
              <select className="border" value={currency} onChange={(e) => setCurrency(e.target.value)} required>
                <option value="">currency</option>
                {options &&
                  options.map((opt) => {
                    return <option value={opt}>{opt}</option>;
                  })}
              </select>
            </div>

            <button type="submit" className="edit-user-btn">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DealerDiscountModal;
