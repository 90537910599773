import {useEffect,useState, useReducer} from 'react'
import useAxiosFetch from '../../hooks/useAxiosFetch';
import VerificationsTables from '../../components/tables/VerificationsTables';
import DocumentTitle from '../../hooks/DocumentTitle';


const VerificationsReport = () => {
  DocumentTitle("Verifications Report");

  const [stats, setStats] = useState(null);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const { data} = useAxiosFetch("/rep/profile_descisions",_);

  useEffect(() => {
      setStats(data?.data);
    }, [data]);
        



  return (
    <div className="user-main-container">
    <div className="user-container">
      <div className="user-header">
        <div className="sub-header">All Time</div>
        <div className="main-header">Profile Verification Report</div>      
      </div>
      <div className="user-table-container rep">
       {stats && <VerificationsTables data={stats}/>}
      </div>
    </div>
  </div>
  );
}

export default VerificationsReport