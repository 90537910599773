
import DocumentTitle from '../hooks/DocumentTitle'
import RecentTransactions from '../components/recentTransactions'
import SampleBar from '../components/sampleBar'
import SampleLine from '../components/sampleLine'
import useAxiosFetch from '../hooks/useAxiosFetch'
import { useState, useEffect,useReducer } from 'react';
import useAuth from '../hooks/useAuth';
import ROLES from '../config.js/roles'

const MainDashboard = () => {
    DocumentTitle('Dashboard')
    


    
  const { auth } = useAuth();
  const [stats, setStats] = useState(null);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  
  const { data } = useAxiosFetch(auth?.role=== ROLES.user  ? `/rep/overal/${auth?.user_id}` :`/rep/overal`, _);

  useEffect(() => {
    setStats(data?.data); 
  }, [data]);

  return (
    <div className="main-dashboard-main">
      <div className="top-card-container">
        <div className="top-card-div">
          <div className="top-info-container">
            <div className="side-box bg-success"><i className="bi bi-check-circle"></i></div>
            <div className="right-section">
              <div className="Header">Total</div>
              <div className="detail">{stats?.completed}</div>
            </div>
          </div>
          <div className="bottom-info-section"> Successful Transaction</div>
        </div>

        <div className="top-card-div">
          <div className="top-info-container">
            <div className="side-box bg-warning"><i className="bi bi-hourglass-split"></i></div>
            <div className="right-section">
              <div className="Header">Total</div>
              <div className="detail">{stats?.pending}</div>
            </div>
          </div>
          <div className="bottom-info-section"> Pending Transaction</div>
        </div>

        <div className="top-card-div">
          <div className="top-info-container">
            <div className="side-box bg-danger"><i className="bi bi-x-octagon"></i></div>
            <div className="right-section">
              <div className="Header">Total</div>
              <div className="detail">{stats?.failed}</div>
            </div>
          </div>
          <div className="bottom-info-section"> Failed Tranactions</div>
        </div>
      </div>

      <div className="large-graph-container">
        <div className="graph-header">
          <div className="sub-header">total</div>
          <div className="main-header">Sales per month</div>
        </div>
        <div className="graph-container"><SampleBar/></div>
      </div>

      <div className="small-graghs-container">

        <div className="small-graph">
          <div className="actual-graph"><SampleLine/></div>
          <div className="graph-description"><i className="bi bi-arrow-down-up"></i> Total Transactions</div>
        </div>

        <div className="small-graph">
          <div className="actual-graph orange"><SampleLine/></div>
          <div className="graph-description"><i className="bi bi-arrow-down-up"></i> Total Sales</div>
        </div>

        <div className="small-graph">
          <div className="actual-graph red"><SampleLine/></div>
          <div className="graph-description"><i className="bi bi-arrow-down-up"></i> Total Purchases</div>
        </div>
      </div>

      <div className="sample-transaction-container">
        <div className="sample-header">
          <div className="sub-header">recent</div>
          <div className="main-header">Latest transaction</div>
        </div>
        <div className="sample-container">
          {stats && <RecentTransactions data={stats?.transaction}/>}
          </div>
      </div>
    </div>
  );
}

export default MainDashboard