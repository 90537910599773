import React from 'react'

const DealerProfileComponent = ({data}) => {
  return (
    <>
            <div className='info-strip'>
                      
                      <div className='user-info'>
                          <div className='info-header'><i className="bi bi-buildings"></i> Name</div>
                          <div className='info-details'>{data?.name}</div>
                      </div>
                      <div className='user-info'>
                          <div className='info-header'><i className="bi bi-list-check"></i> Type</div>
                          <div className='info-details'>{data?.type}</div>
                      </div>
                      <div className='user-info'>
                          <div className='info-header'><i className="bi bi-patch-check"></i> Status</div>
                          <div className='info-details'>{data?.status}</div>
                      </div>
            </div>
            <div className='info-strip'>
                      
                      <div className='user-info'>
                          <div className='info-header'><i className="bi bi-flag"></i> Country</div>
                          <div className='info-details'>{data?.country}</div>
                      </div>
                      <div className='user-info'>
                          <div className='info-header'><i className="bi bi-building"></i> City</div>
                          <div className='info-details'>{data?.city}</div>
                      </div>
                      <div className='user-info'>
                          <div className='info-header'><i className="bi bi-signpost-2"></i> Address</div>
                          <div className='info-details'>{data?.address}</div>
                      </div>
            </div>
            <div className='info-strip'>
                      
                      <div className='user-info'>
                          <div className='info-header'><i className="bi bi-telephone"></i> Phone</div>
                          <div className='info-details'>{data?.country_code}{data?.cell}</div>
                      </div>
                      <div className='user-info'>
                          <div className='info-header'><i className="bi bi-telephone-plus"></i> Contact Details</div>
                          <div className='info-details'>{data?.contact_details}</div>
                      </div>
                      <div className='user-info'>
                          <div className='info-header'><i className="bi bi-envelope-at"></i> Email </div>
                          <div className='info-details'>{data?.company_email}</div>
                      </div>
            </div>
    </>
  )
}

export default DealerProfileComponent