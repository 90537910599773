import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";

const ApiModal = ({setShowModal,data}) => {
    const handleClose =()=>{
        setShowModal(false)
    }
  return (
    <div className="any-modal-main">
      <div className='api-modal-body'>
        <button className="sale-close-modal-btn" onClick={handleClose}><FontAwesomeIcon icon={faCircleXmark} /></button>
        <div className='api-data'>
                <div className='info-strip'>
                        <div className='user-info'>
                            <div className='info-header'>Name</div>
                            <div className='info-details'>{data?.client_name}</div>
                        </div>
                </div>
                <div className='info-strip'>
                        <div className='user-info'>
                            <div className='info-header'>Client ID</div>
                            <div className='info-details'>{data?.client_id}</div>
                        </div>

                </div>
                <div className='info-strip formss'>
                        <div className='user-info'>
                            <div className='info-header'>Client Secret</div>
                            <form>
                                <textarea id="api" rows="3" col="100">{data?.client_secret}</textarea>
                            </form>
                        </div>

                </div>
                <div className='info-strip formss'>
                        <div className='user-info '>
                            <div className='info-header'>API Key</div>
                            <form>
                                <textarea id="api" rows="3" col="100">{data?.api_key}</textarea>
                            </form>
                        </div>
                </div>
            </div>
        <p className='import-txt-api'>*Please copy the credentials, you will nerver see them again</p>

        </div>
    </div>
  );
}

export default ApiModal