
const DocumentTypes = (type)=>{
    let data = null
    if(type === "COMPANY"){
        data = [
            "Application Form",
            "Certificate of Incorporation",
            "Company Registration",
            "Tax Clearance",
            "Directors National Identity",
            "Proof of Residence"
        
        ]
    }
    else if(type === "PARTNERSHIP"){
        data = [
            "Application Form",
            "Partnership Agreement",
            "Trading Licence.",
            "Coloured Passport Size Photo of Partners",
            "Directors National Identity",
            "Proof of Residence",
            "Tax Clearance" 
        ]
    }
    else if(type === "SOLE_TRADER"){
        data = [
            "Application Form",
            "Trading Licence.",
            "Coloured Passport Size Photo of Director",
            "National Identity Document",
            "Proof of Residence",
        ]
    }
    else if(type === "NGO_CHURCH"){
        data = [
            "Application Form",
            "Copy of Constitution",
            "Coloured Passport Size Photoof the Trustees",
            "Copies of IDs of Trustees and transactors",
            "Proof of Residence",
            "Business permits",
        ]
    }
    else if(type === "SOCIETY"){
        data = [
            "Application Form",
            "List Executive Committee",
            "Coloured Passport Size Photo of the Trustees",
            "Copies of IDs of Executive Committe Members and Transactors",
            "Proof of Residence",
        ]
    }
    else{
        data=[]
    }

return data
}

export default DocumentTypes

