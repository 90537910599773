import React from 'react'
import { useState, useEffect,useReducer } from 'react';
import Alert from '../alert';
import useAxiosFetch from '../../hooks/useAxiosFetch'
import AddNotificationUser from '../forms/addNotificationUserForm';
import NotificationUsers from './notification_users';
import VerifyNotificationUserModal from '../modals/verifyNotificationUserModal';

const NotificationUserComponent = () => {
    const [showModal,setShowModal] = useState(null)
    const [selectedRow,setSelectedRow] = useState(null)
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const [stats, setStats] = useState(null);
    const [_, forceUpdate] = useReducer((x) => x + 1, 0);

    const { data} = useAxiosFetch(`/admin/notification_users`, _);
    useEffect(() => {
      setStats(data?.data);
    }, [data]);


  return (
      <>
        {errorMessage && <Alert message={errorMessage} setMessage={setErrorMessage} type={"error"}/>}
        {successMessage && <Alert message={successMessage} setMessage={setSuccessMessage} type={"success"}/>}
        <div className="section-header">
          <div className="sub-header">All System Users</div>
          <div className="main-header">Notification Receivers</div>
        </div>
        <div className='actual-constants'>
          {stats && <NotificationUsers data={stats} setSelectedRow={setSelectedRow} setShowModal={setShowModal}/>}
        </div>
        
        <div className='constants-update-section'>
         {stats && <AddNotificationUser setErrorMessage={setErrorMessage} setSuccessMessage={setSuccessMessage} forceUpdate={forceUpdate} data={stats}/>}
        </div>
        
        {showModal && <VerifyNotificationUserModal forceUpdate={forceUpdate} setShowModal={setShowModal} data={selectedRow}/>}
        
        
      </>
  )
}

export default NotificationUserComponent