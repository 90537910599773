import React from 'react'
import { useState, useEffect } from 'react'


const DeleteProfileComponent = ({data, setErrorMessage, setShowDeleteAccountModal}) => {
    const [match, setMatch] = useState(false)
    const [text, setText] = useState("")
    const parameter = `acc/${data?.first_name}/del`


    useEffect(()=>{
        if(text === parameter){
           setMatch(true)
        }
    },[text])


  return (
    <div className='delete-profile-cont'>
        <p className='txt-header text-danger'>*Danger Zone</p>
        <p className='txt-para text-danger'>*You are now deleting your account. please repeat the text "{parameter}"</p>
        <div className='del-form-cont'>
            <form className='del-user-form'>
                <div className="input-group">
                <input className='border' type='text'  name='new_password' value={text} onChange={(e)=> setText(e.target.value)} placeholder='Enter text...'  required/>
                <span className="input-group-text" id="basic-addon4" onClick={match ? ()=>setShowDeleteAccountModal(true) : ()=>setErrorMessage("The input does'nt match the provided text.")}><i className="bi bi-trash3" ></i></span>
                </div>
            </form>
        </div>

    </div>
  )
}

export default DeleteProfileComponent