import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import Alert from "../alert";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useState, useEffect } from "react";

const VerifyDealerModal = ({ setShowModal, forceUpdate, data }) => {
  const VERIFICATION_URL = `admin/update_profile/${data?.id}`;
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [status, setStatus] = useState("");
  const [reason, setReason] = useState("");

  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    setErrorMessage("");
  }, [status,reason]);

  const handleClose = () => {
    setShowModal(false);
  };

  const handleVerify = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    setLoading(true);
    const sdata = {
      status: status,
      reason: reason,
    };

    try {
      const response = await axiosPrivate.post(VERIFICATION_URL, { ...sdata });
      setSuccessMessage(response?.data?.message);
      setStatus('')
      setReason('')
      setLoading(false);
      forceUpdate();
    } catch (err) {
      if (!err?.response) {
        setErrorMessage("No server response!");
        setLoading(false);
      } else {
        setErrorMessage(err.response?.data?.message);
        setLoading(false);
      }
    }
  };
  return (
    <div className="inner-modal-main">
      <div className="inner-modal-body smallc">
        <button className="inner-close-modal-btn" onClick={handleClose}>
          <FontAwesomeIcon icon={faCircleXmark} />
        </button>
        {errorMessage && (
          <Alert
            message={errorMessage}
            setMessage={setErrorMessage}
            type={"error"}
          />
        )}
        {successMessage && (
          <Alert
            message={successMessage}
            setMessage={setSuccessMessage}
            type={"success"}
          />
        )}
        <div className="edit-user-form-container">
          <form className="edit-user-form" onSubmit={handleVerify}>
            <div className="modal-heading">Verifying Dealer {data?.company_profile?.name || "Anonymous"}</div>
            <div className="input-group">
              <span className="input-group-text" id="basic-addon2"> <i className="bi bi-chat-left-dots"></i>
              </span>
              <input className="border" type="text" name="reason" value={reason} onChange={(e) => setReason(e.target.value)} placeholder="reason" required/>
            </div>

            <div className="input-group">
              <span className="input-group-text" id="basic-addon3"> <i className="bi bi-patch-check"></i>
              </span>
              <select className="border" value={status} onChange={(e) => setStatus(e.target.value)} placeholder="Amount" required>
                <option value="">status</option>
                <option value="VERIFIED">VERIFIED</option>
                <option value="REJECTED">REJECTED</option>
              </select>
            </div>

            <button type="submit" className="edit-user-btn">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default VerifyDealerModal;
