import React from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth'
import ROLES from '../../config.js/roles'

const MoreOptionsComponent = ({setErrorMessage, setLoading, setSuccessMessage, forceUpdate, id ,setShowVerifyDealerModal, setShowChangeRoleModal, setShowDealerDiscModal, setShowLoadAccountModal,setShowEcoPurchaseModal,setShowOtherPurchaseModal , setShowDiscountCalculatorModal}) => {
    const {auth} = useAuth()
    const axiosPrivate = useAxiosPrivate()

    const handleDelete = async()=>{
        setErrorMessage("")
        setSuccessMessage("")
        setLoading(true)

        try{
          const response = await axiosPrivate.get(`/admin/delete_account/${id}`)
          setSuccessMessage(response?.data?.message)
          setLoading(false)
          forceUpdate()
      }
      catch(err){
              setErrorMessage(err?.response?.data?.message)
              setLoading(false)
          }

    } 

  return (
    <>
        <div className="more-options dropdown-toggle-split my-text-dull-white" data-bs-toggle="dropdown" aria-expanded="false"><i className="bi bi-list-ul my-text-dull-white"></i> Options</div>
        <ul className="dropdown-menu">
        {auth?.role !== ROLES.user &&  <div className='actual-top-link dropdown-item' onClick={()=>setShowVerifyDealerModal(true)} ><i className="bi bi-patch-check"></i><span> Verify Dealer</span> </div>}
        {auth?.role === ROLES.admin &&         <div className='actual-top-link dropdown-item' onClick={()=>setShowChangeRoleModal(true)} ><i className="bi bi-feather"></i><span> Change Role</span> </div>}
        {/* {auth?.role !== ROLES.user &&         <div className='actual-top-link dropdown-item' onClick={()=>setShowDealerDiscModal(true)} ><i className="bi bi-percent"></i><span> Custom Discount</span> </div>} */}
        {auth?.role !== ROLES.user &&         <div className='actual-top-link dropdown-item' onClick={()=>setShowLoadAccountModal(true)} ><i className="bi bi-currency-exchange"></i><span> Recharge Acc</span> </div>}
        <div className='actual-top-link dropdown-item' onClick={()=>setShowDiscountCalculatorModal(true)} ><i className="bi bi-calculator"></i> <span>Discount Calculator</span></div>
        {auth?.role === ROLES.user &&       <li><hr className="dropdown-ulider"/></li> }
         {auth?.role === ROLES.user &&       <h6 className="dropdown-header">Purchase Airtime</h6>}
         {auth?.role === ROLES.user &&       <div className='actual-top-link dropdown-item' onClick={()=>setShowEcoPurchaseModal(true)} ><i className="bi bi-wallet2"></i> <span> Ecocash</span> </div>}
         {auth?.role === ROLES.user &&      <div className='actual-top-link dropdown-item' onClick={()=>setShowOtherPurchaseModal(true)} ><i className="bi bi-credit-card-2-front"></i> <span> Proof of Payment</span></div>}

           <li><hr className="dropdown-divider"/></li>
        {auth?.role === ROLES.admin &&         <div className='actual-top-link dropdown-item' onClick={handleDelete} ><i className="bi bi-trash3"></i> <span className='my-text-red'>Delete Account</span> </div>}
        </ul>
    </>
  )
}

export default MoreOptionsComponent