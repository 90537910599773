import React from 'react'

const AirtimeAccounts = ({data}) => {

  return (
    <div className='airtime-acc-container'>
      {data.length > 0 ? data.map((rec) =>{
         let txtclr = ""
         if(rec?.account_balance > 50){
               txtclr="text-success"
         }
         else{
           txtclr="text-danger"
         }
    return  ( 
    <div className='airtime-account' key={rec?.id}>
         <div className='acc-info'>acc# <span>{rec?.account_number}</span></div>
         <div className='acc-info'>currency <span>{rec?.currency}</span></div>
         <div className='acc-info'>balance <span className={txtclr}>{rec?.account_balance}</span></div>
     </div>)
      }) :  <div className='no-data-sec'> No Airtime Accounts Available</div>}
    </div>
  )
}

export default AirtimeAccounts