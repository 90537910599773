import React from 'react'
import { useState, useEffect,useReducer } from 'react';
import useAxiosFetch from '../../hooks/useAxiosFetch'
import ConstantComponent from './constantComponent';
import EditConstantModal from '../modals/editConstantModal';

const ConstantsSecComponent = () => {
    const [showConModal,setShowConModal] = useState(null)
    const [selectedConRow,setSelectedConRow] = useState(null)
    const [stats, setStats] = useState(null);
    const [_, forceUpdateToo] = useReducer((x) => x + 1, 0);

    const { data} = useAxiosFetch(`/cons/constants`, _);
    useEffect(() => {
      setStats(data.data);
    }, [data]);

    
  return (
      <>
        <div className="section-header">
          <div className="sub-header">All System Limits and discounts</div>
          <div className="main-header">Constants</div>
        </div>
        <div className='actual-constants fullc'>
          {stats && <ConstantComponent data={stats} setSelectedRow={setSelectedConRow} setShowModal={setShowConModal} />}
        </div>
        {showConModal && selectedConRow && <EditConstantModal data={selectedConRow} forceUpdate={forceUpdateToo} setShowModal={setShowConModal}/>}
      </>
  )
}

export default ConstantsSecComponent