import { useState} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';


const AddNotificationUser = ({forceUpdate, setSuccessMessage, setErrorMessage, data}) => {
    const [email, setEmail] = useState('')
    const [loading, setLoading] =useState(false)
    const axiosPrivate  = useAxiosPrivate()

    const handleTopUp= async(e)=>{
      e.preventDefault()
      setLoading(true)
      setErrorMessage("")
      setSuccessMessage("")
      let data={
        email: email,
   }

      try{
          const response = await axiosPrivate.post(`/admin/notification_users`, {...data})
          setSuccessMessage(response?.data?.message)
          setEmail("")
          forceUpdate()
          setLoading(false)
      }catch(err){
        setErrorMessage(err.response?.data?.message)
        setLoading(false)
      }
      }
  

  return (
    <form className='constant-form' onSubmit={handleTopUp}>
    <p className='form-header '>Add User To Notification List</p>
    <div className="input-group">
      <span className="input-group-text" id="basic-addon3"><i className="bi bi-envelope-at"></i></span>
      <input className='border' type='email'  name='email'  value={email} onChange={(e)=>setEmail(e.target.value)} placeholder='User Email'  required/>
    </div>

    {loading ?<button className='edit-user-btn' type='submit'><FontAwesomeIcon icon={faArrowsRotate} className='spinner'/></button> : <button className='edit-user-btn' type='submit'>Add User</button> }
  </form>
  )
}

export default AddNotificationUser