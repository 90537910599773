
import DocumentTitle from '../hooks/DocumentTitle';
import SalesModal from '../components/modals/salesModal';
import useAxiosFetch from '../hooks/useAxiosFetch'
import { useState, useEffect,useReducer } from 'react';
import Alert from '../components/alert';

const DiscountsPage = () => {
    DocumentTitle("Sales");
    const [showModal, setShowModal] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const [stats, setStats] = useState(null);
    const [_, forceUpdate] = useReducer((x) => x + 1, 0);


  
  const { data} = useAxiosFetch(`/admin/discounts`, _);

  useEffect(() => {
    setStats(data?.data);
  }, [data]);



  return (
    <div className="discounts-main-container">
        {errorMessage && <Alert message={errorMessage} setMessage={setErrorMessage} type={"error"}/>}
        {successMessage && <Alert message={successMessage} setMessage={setSuccessMessage} type={"success"}/>}
      <div className="discount-container">
        <div className="discount-header">
          <div className="sub-header">All</div>
          <div className="main-header">Airtime Purchase Discounts</div>
        </div>
        <div className="discounts">
          {/* {stats &&  <DiscountComponents data={stats}/>} */}
        </div>
        <div className="discounts-form">
          <div className='disc-form-header'>Update Discount</div>
          {/* {stats &&  <UpdateDiscountForm setErrorMessage={setErrorMessage} setSuccessMessage={setSuccessMessage} forceUpdate={forceUpdate}/>} */}
        </div>
      </div>
    {showModal && <SalesModal setShowModal={setShowModal} data={selectedRow}/>}
    </div>
  );
}

export default DiscountsPage