import { useState, useEffect } from "react";
import Alert from "../alert";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import useAuth from "../../hooks/useAuth";

const NewTicketModal = ({ setModalToo, forceUpdate }) => {
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("PAY");
  const [description, setDescription] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const NEW_URL = `/ticket/create_ticket/${auth?.user_id}`;

  useEffect(() => {
    setErrorMessage("");
  }, [title, category, description]);

  const handleClose = () => {
    setModalToo(false);
  };

  const handleNewTicket = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    try {
      const response = await axiosPrivate.post(NEW_URL, {
        title,
        category,
        description,
      });
      setSuccessMessage(response?.data?.message);
      setDescription("");
      setTitle("");
      forceUpdate();
    } catch (err) {
      setErrorMessage(err.response?.data?.message);
    }
  };

  return (
    <div className="any-modal-main">
      <div className="new-ticket-modal-container">
        {errorMessage && ( <Alert message={errorMessage} setMessage={setErrorMessage} type={"error"}/> )}
        {successMessage && (<Alert message={successMessage} setMessage={setSuccessMessage} type={"success"}/>)}

        <button className="nt-close-modal-btn" onClick={handleClose}> <FontAwesomeIcon icon={faCircleXmark} /></button>
        <div className="new-ticket-header">New Ticket</div>
        <form className="new-ticket-form" onSubmit={handleNewTicket}>
          <input className="nt-form-input" type="text" id="title" name="title" placeholder="Title..." value={title} onChange={(e) => setTitle(e.target.value)} required/>
          <select
            className="nt-form-input biggr"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          >
            <option value="">category</option>
            <option value="GENERAL">GENERAL</option>
            <option value="PURCHASE">PURCHASE</option>
            <option value="SALE">SALE</option>
            <option value="ACCOUNT">ACCOUNT</option>
            <option value="API_CLIENT">API_CLIENT</option>
          </select>
          <textarea
            className="nt-form-area"
            rows={6}
            name="description"
            placeholder="Description..."
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          ></textarea>
          <button className="nt-form-btn" type="submit">
            Create
          </button>
        </form>
      </div>
    </div>
  );
};

export default NewTicketModal;
