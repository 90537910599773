import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import Alert from '../alert';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import {useState, useEffect} from 'react'

const UpdateChangeRequestModal = ({setShowModal, forceUpdate, data}) => {
    const UPDATE_URL = `cons/effect_change`;
    const [status, setStatus] = useState("")
    const [changereq, setChangereq] = useState("")
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [loading, setLoading] =useState(false)
    const axiosPrivate  = useAxiosPrivate()

    useEffect(()=>{
        setErrorMessage("")
    },[changereq,status]) 


    const handleClose =()=>{
        setShowModal(false)
    }

    const handleUpdate =async(e)=>{
        e.preventDefault()
        setErrorMessage("")
        setSuccessMessage("")
        setLoading(true)
        let formdata = new FormData()   
        formdata.append('status',status)
        formdata.append('title', data?.title) 
        formdata.append('change_ref', data?.change_ref)
        formdata.append('change_req_doc', changereq)
        const headers = {
          'Content-Type': 'multipart/form-data'
        }
     

        try{
            const response = await axiosPrivate.post(UPDATE_URL, formdata, {headers:{...headers}} )
            setSuccessMessage(response?.data?.message)
            setChangereq('')
            setStatus('')
            setLoading(false)
            forceUpdate()
        }
        catch(err){
            if (!err?.response) {
                setErrorMessage("No server response!");
                setLoading(false)
            }
            else{
                setErrorMessage(err.response?.data?.message)
                setLoading(false)
            }
        }
        

    }

  return (
    <div className="inner-modal-main">
      <div className='inner-modal-body'>
        <button className="inner-close-modal-btn" onClick={handleClose}><FontAwesomeIcon icon={faCircleXmark} /></button>
        {errorMessage && <Alert message={errorMessage} setMessage={setErrorMessage} type={"error"}/>}
        {successMessage && <Alert message={successMessage} setMessage={setSuccessMessage} type={"success"}/>}
        <form className='constant-form fullcModal' onSubmit={handleUpdate}>
            <p className='form-header '>Update value change.</p>
            <div className="input-group"> 
                <span className="input-group-text" id="basic-addon3"><i className="bi bi-cloud-arrow-up"></i></span>
                <input className='border ' type='file' accept="application/pdf"  name='chagereq'  onChange={(e)=>setChangereq(e.target.files[0])} placeholder='chare request'  required/>
            </div>
            
            <div className="input-group">
                <span className="input-group-text" id="basic-addon2"> <i className="bi bi-file-earmark-check"></i></span>
                <select className='border' value={status} onChange={(e)=>setStatus(e.target.value)}    required>
                    <option value="">Status</option>
                    <option value="REJECTED">REJECTED</option>
                    <option value="APPROVED">APPROVED</option>
                </select>
            </div>
           {loading ?<button className='btn my-btn-blue' type='submit'><FontAwesomeIcon icon={faArrowsRotate} className='spinner'/></button> : <button className='btn my-btn-blue' type='submit'>Submit</button> }
        </form>
         <p className='import-txt'>*these actions can not be reversed</p>  
        </div>
    </div>
  );
}

export default UpdateChangeRequestModal