import React from "react";
import ApiClient from "../apiClient";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ROLES from "../../config.js/roles";
import useAuth from "../../hooks/useAuth";

const ApiClientComponet = ({data,setApiData,setErrorMessage,setSuccessMessage,setShowModal,}) => {
  const axiosPrivate = useAxiosPrivate();
  const {auth} = useAuth()

  const handleResetAPI = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    try {
      const response = await axiosPrivate.get( `/admin/reset_api/${data?.user_id}`);
      setSuccessMessage(response?.data?.message);
      setApiData(response?.data?.data);
      setShowModal(true);
    } catch (err) {
      setErrorMessage(err?.response?.data?.message);
    }
  };
  return (
    <div className="inner-comp-container">
      <div className="sample-header">
        <div className="sub-header">All Clients</div>
        <div className="main-header">API Client</div>
      </div>
      <div className="api-section">
        {data ? <ApiClient data={data} />: <div className="no-data-sec"> No Accounts Available</div>}
      </div>
      {auth?.role === ROLES.admin && <div className="reset-btn-section">
        <button onClick={handleResetAPI}>Reset</button>
      </div>}
    </div>
  );
};

export default ApiClientComponet;
