import React from 'react'
import KycDocument from '../kycDocument'

const KycUploadsComponent = ({data}) => {
  return (
    <div className='inner-comp-container'>
        <div className="sample-header">
            <div className="sub-header">All Uploads</div>
            <div className="main-header">KYC Documents</div>
        </div>
        <div className="kyc-section">
            {data  ? <KycDocument data={data}/> : <div className='no-data-sec'> No Documents Available</div>}
        </div>
    </div>
  )
}

export default KycUploadsComponent