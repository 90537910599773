import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import Alert from '../alert';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import {useState, useEffect} from 'react'
import CURRENCY from "../../config.js/currency";


const EditConstantModal = ({setShowModal, forceUpdate,  data}) => {
    const EDIT_URL = `/cons/request_change`;

    const [value, setValue] = useState(data?.value || 0)
    const [amount, setAmount] = useState(data?.amount || 0)
    const [currency, setCurrency] = useState(data?.currency || " ")
    const [title, setTitle] = useState(data?.title || "Not Set")
    const [loading, setLoading] =useState(false)

    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const options = CURRENCY
    const axiosPrivate  = useAxiosPrivate()



    useEffect(()=>{
        setErrorMessage("")
    },[]) 


    const handleClose =()=>{
        setShowModal(false)
    }

    const handleConEdit= async(e)=>{
        e.preventDefault()
        setLoading(true)
        setErrorMessage("")
        setSuccessMessage("")
        let data={
          value: value,
          amount: amount,
          currency : currency,
          title: `${title}`
     }
  
        try{
            const response = await axiosPrivate.post(EDIT_URL, {...data})
            setSuccessMessage(response?.data?.message)
            forceUpdate()
            setLoading(false)
        }catch(err){
          setErrorMessage(err.response?.data?.message)
          setLoading(false)
        }
        }
        

    
  return (
    <div className="inner-modal-main">
        <div className='inner-modal-body smallc'>
            <button className="inner-close-modal-btn" onClick={handleClose}><FontAwesomeIcon icon={faCircleXmark} /></button>
            {errorMessage && <Alert message={errorMessage} setMessage={setErrorMessage} type={"error"}/>}
            {successMessage && <Alert message={successMessage} setMessage={setSuccessMessage} type={"success"}/>}
            <div className='edit-user-form-container'>
        
                <form className='edit-user-form' onSubmit={handleConEdit}>
                    <p className='modal-heading'>Request for a value change for a {data?.type || "Annonymous"}</p>
                    <div className="input-group">
                    <span className="input-group-text" id="basic-addon1"><i className="bi bi-diagram-2"></i></span>
                    <input className='border'  value={title} onChange={(e)=>setTitle(e.target.value)}    required/>
                    </div>

                    <div className="input-group">
                    <span className="input-group-text" id="basic-addon2"><i className="bi bi-currency-exchange"></i></span>
                    <select className='border' value={currency} onChange={(e)=>setCurrency(e.target.value)}    required>
                        <option value="">Currency</option>
                        {options && options.map((opt)=>{ return <option value={opt}>{opt}</option>})}
                    </select>
                    </div>

                    <div className="input-group">
                    <span className="input-group-text" id="basic-addon3"><i className="bi bi-percent"></i></span>
                    <input className='border' type='number'  name='value'  value={value} onChange={(e)=>setValue(e.target.value)} placeholder='value'  required/>
                    </div>
                    <div className="input-group">
                    <span className="input-group-text" id="basic-addon3"><i className="bi bi-currency-dollar"></i></span>
                    <input className='border' type='number'  name='amount'  value={amount} onChange={(e)=>setAmount(e.target.value)} placeholder='amount'  required/>
                    </div>

                    <button type='submit' className='edit-user-btn'>Request</button>
                </form>
            </div>
                    
        </div>
    </div>
  );
}

export default EditConstantModal