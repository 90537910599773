import React from 'react'
import { useState, useEffect, useReducer} from 'react'
import UpdateTransModal from '../components/modals/updateTransModal'
import useAxiosFetch from '../hooks/useAxiosFetch'
import { useParams } from "react-router-dom";
import useAuth from '../hooks/useAuth'
import ROLES from '../config.js/roles'
import FILE_BASE_URL from '../config.js/fileBase';


const SingleBank = () => {
    const {id} = useParams();
    const {auth}= useAuth()
    const [stats, setStats] = useState(null);
    const [showModal, setShowModal] = useState(false)
    const [_, forceUpdate] = useReducer((x) => x + 1, 0);
    const { data} = useAxiosFetch( `/rep/bank_purchase/${id}`, _);
  

    useEffect(() => {
      setStats(data?.data);
    }, [data]);
 

  return (
    <div className='search-trans-main'>

        <div className='st-midpart'>
            <div className='with-data'>
                <div className='info-strip'>
                        <div className='user-info'>
                            <div className='info-header'>Full Name</div>
                            <div className='info-details'>{stats?.user?.first_name} {stats?.user?.last_name}</div>
                        </div>
                        <div className='user-info'>
                            <div className='info-header'>Email</div>
                            <div className='info-details'>{stats?.user?.email}</div>
                        </div>
                        <div className='user-info'>
                            <div className='info-header'>Account #</div>
                            <div className='info-details'>{stats?.account_number}</div>
                        </div>
                </div>
                <div className='info-strip'>
                        <div className='user-info'>
                            <div className='info-header'>Ref ID</div>
                            <div className='info-details'>{stats?.reference_id}</div>
                        </div>
                        <div className='user-info'>
                            <div className='info-header'>Currency</div>
                            <div className='info-details'>{stats?.currency}</div>
                        </div>
                        <div className='user-info'>
                            <div className='info-header'>Status</div>
                            <div className='info-details'>{stats?.transaction_status}</div>
                        </div>

                </div>
                <div className='info-strip'>

                        <div className='user-info'>
                            <div className='info-header'>Channel</div>
                            <div className='info-details'>{stats?.channel}</div>
                        </div>
                        <div className='user-info'>
                            <div className='info-header'>Gateway</div>
                            <div className='info-details'>{stats?.payment_gateway}</div>
                        </div>
                        <div className='user-info'>
                            <div className='info-header'>POP</div>
                            <div className='info-details'><a href={FILE_BASE_URL+stats?.pop_upload?.url} target='_blank'>open</a></div>
                        </div>
                </div>
                <div className='info-strip'>

                        <div className='user-info'>
                            <div className='info-header'>Ammount Purchased</div>
                            <div className='info-details numc'>{stats?.amount_purchased}</div>
                        </div>
                        <div className='user-info'>
                            <div className='info-header'>Ammount Paid</div>
                            <div className='info-details numc'>{stats?.amount_paid}</div>
                        </div>
                </div>
            </div>
            {/* <div className='no-data'>NO DATA</div> */}
        </div>
        {auth?.role !== ROLES.user &&    <div className='st-bottompart'>
                <button className='trans-btn' onClick={()=>setShowModal(true)} >Update</button>
        </div>}

        {showModal && <UpdateTransModal setShowModal={setShowModal} data={stats} forceUpdate={forceUpdate} type={"BANK"}/>}
    </div>
  )
}

export default SingleBank