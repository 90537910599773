import {useState, useEffect, useReducer} from 'react'
import Alert from '../components/alert';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser, faClock, faCheckCircle, faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useParams } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import useAxiosFetch from '../hooks/useAxiosFetch';
import ROLES from '../config.js/roles';

const SingleTicketPage = () => {
      const {auth} = useAuth()
      const{id} = useParams()
      const [comment, setComment] = useState("")
      const [ticket, setTicket] = useState(null);
      const [_, forceUpdate] = useReducer((x) => x + 1, 0);
      const { data} = useAxiosFetch(`/ticket/single_tickets/${id}`,_);
      const axiosPrivate  = useAxiosPrivate()
      const [errorMessage, setErrorMessage] = useState("");
      const [successMessage, setSuccessMessage] = useState("");
      const COMMENT_URL = `/ticket/comment_ticket/${id}`;
      const CLOSE_URL = `/ticket/close_ticket/${id}`;
      const REOPEN_URL = `/ticket/reopen_ticket/${id}`;
      const [loading, setLoading] =useState(false)


      
  
  useEffect(() => {
          setTicket(data?.data);
        }, [data]);

  const handleComment= async(e)=>{
        e.preventDefault()
        setErrorMessage("")
        setSuccessMessage("")

        try{
            await axiosPrivate.post(COMMENT_URL, {comment})
            forceUpdate()
            setComment("")
        }catch(err){
          setErrorMessage(err?.response?.data?.message)
        }
         
      }
  const handleClose= async(e)=>{
        setErrorMessage("")
        setSuccessMessage("")
        setLoading(true)
        try{
            const response = await axiosPrivate.post(CLOSE_URL, {comment})
            setSuccessMessage(response?.data?.message)
            forceUpdate();
            setLoading(false)
        }catch(err){
          setErrorMessage(err?.response?.data?.message)
          setLoading(false)
        }
         
      }
  const handleReOpen= async(e)=>{
        setErrorMessage("")
        setSuccessMessage("")
        try{
            const response = await axiosPrivate.post(REOPEN_URL, {comment})
            setSuccessMessage(response?.data?.message)
            forceUpdate();
        }catch(err){
          setErrorMessage(err?.response?.data?.message)
        }
         
      }


  return (
    <div className='single-ticket-page-main'>
                {errorMessage && <Alert message={errorMessage} setMessage={setErrorMessage} type={"error"}/>}
                {successMessage && <Alert message={successMessage} setMessage={setSuccessMessage} type={"success"}/>}
  
        <div className='stp-ticket-section'>
            <div className='ticket-details'>
               <div className='ticket-owner'><FontAwesomeIcon icon={faCircleUser}/> <span>{ticket?.user?.first_name || 'Null'} {ticket?.user?.last_name || 'Null'} </span></div>
               <div className='ticket-time'><FontAwesomeIcon icon={faClock}/> <span> {ticket?.createdAt.slice(0,10) || 'Null'}</span></div>
               <div className='ticket-status'><FontAwesomeIcon icon={faCheckCircle}/> <span> {ticket?.status || 'Null'}</span></div>
            </div> 

            <div className='ticket-title'> {ticket?.title || 'No Title'}</div>
            <div className='ticket-description'> {ticket?.description || 'No Description'}.</div>
            
            <div className='ticket-buttons'>
          {  auth?.role !== ROLES.user && loading && <button className='tb-btn-close' ><FontAwesomeIcon icon={faArrowsRotate} className='spinner'/></button>}
          {  auth?.role !== ROLES.user && !loading &&  <button className='tb-btn-close' onClick={handleClose}>Close</button>}
           {auth?.user_id === ticket?.user_id  && ticket?.status ==="CLOSED" &&   <button className='tb-btn-reopen' onClick={handleReOpen}>Re-Open</button>}
            </div>
        </div>


        <div className='stp-comments-section'> 
            <div className='ticket-comments'>
              {ticket?.comments.length > 0 && ticket?.comments.map((commentRec) =>{
                if(commentRec?.user_id === auth?.user_id){
                    return ( <div className='actual-comment left'>
                       <div className='comment-bubble'>
                        <div className='comment-txt'>{commentRec?.comment}</div>
                        <div className='comment-time'> {commentRec?.createdAt.slice(0,10)}</div>
                      </div>
                     </div>)                  
                }
                else{
                       return (<div className='actual-comment right'>
                        <div className='comment-bubble'>
                          <div className='comment-time'> {commentRec?.user?.first_name} {commentRec?.user?.last_name}</div>
                          <div className='comment-txt'> {commentRec?.comment}</div>
                          <div className='comment-time'> {commentRec?.createdAt.slice(0,10)}</div>
                        </div>
                      </div>)                   
                }

              })}
            </div>
          {auth?.role !== ROLES.data_capture && (<div className='ticket-comments-form'>
               <form className='create-comment-form' onSubmit={handleComment}>
                 <input type="text" name='coment' placeholder='Comment...' value={comment} onChange={(e)=>setComment(e.target.value)} required/>
                 <button className='comment-button'><FontAwesomeIcon icon={faPaperPlane}/></button>
               </form>
            </div>)}
        </div>
    </div>
  )
}

export default SingleTicketPage