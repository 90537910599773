import React from 'react'
import FILE_BASE_URL from '../config.js/fileBase'

const KycDocument = ({data}) => {
     

  
  return (
    <div className='constant-container'>
      {data && data.length > 0 ? data.map((rec)=>{
        return(
        <div key={rec?.id} className='kyc-doc-div'>
            <p><a className='txt-no-deco' href={FILE_BASE_URL+rec?.url} target='_blank'>{rec?.category}</a></p>
        </div>)
      }):  <div className='no-data-sec'> No Files Available</div>}

    </div>
  )
}

export default KycDocument