import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import Alert from '../alert';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import {useState, useEffect} from 'react'

const CreateUserModal = ({setShowModal, forceUpdate}) => {
    const REGISTRATION_URL = "/admin/create_user";
    const [first_name, setFirstName] = useState("")
    const [last_name, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [role, setRole] = useState("")
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [loading, setLoading] =useState(false)
    const axiosPrivate  = useAxiosPrivate()

    useEffect(()=>{
        setErrorMessage("")
    },[first_name,last_name,email,role])


    const handleClose =()=>{
        setShowModal(false)
    }

    const handleRegistration =async(e)=>{
        e.preventDefault()
        setErrorMessage("")
        setSuccessMessage("")
        setLoading(true)

        try{
            const response = await axiosPrivate.post(REGISTRATION_URL,{first_name,last_name,email,role})
            setSuccessMessage(response?.data?.message)
            setEmail("")
            setFirstName("")
            setLastName("")
            setRole("")
            setLoading(false)
            forceUpdate()
        }
        catch(err){
            if (!err?.response) {
                setErrorMessage("No server response!");
                setLoading(false)
            }
            else{
                setErrorMessage(err.response?.data?.message)
                setLoading(false)
            }
        }
        

    }

  return (
    <div className="any-modal-main">
      <div className='sale-modal-body'>
        <button className="sale-close-modal-btn" onClick={handleClose}><FontAwesomeIcon icon={faCircleXmark} /></button>
        {errorMessage && <Alert message={errorMessage} setMessage={setErrorMessage} type={"error"}/>}
        {successMessage && <Alert message={successMessage} setMessage={setSuccessMessage} type={"success"}/>}
         
        <form className='registration-form' onSubmit={handleRegistration}>
                    <input className='reg-form-input' type='text' id='first_name' name='first_name' placeholder='First Name' value={first_name} onChange={(e)=> setFirstName(e.target.value)} required/>
                    <input className='reg-form-input' type='text' id='last_name' name='last_name' placeholder='Last Name' value={last_name} onChange={(e)=> setLastName(e.target.value)} required/>
                    <input className='reg-form-input' type='email' id='email' name='email' placeholder='Email' value={email} onChange={(e)=> setEmail(e.target.value)} required/>
                    <select className='reg-form-input' value={role} onChange={(e)=>setRole(e.target.value)} placeholder='Amount'  required>
                            <option value="">role</option>
                            <option value="USER">USER</option>
                            <option value="AGENT">AGENT</option>
                            <option value="DATA_CAPTURE">DATA_CAPTURE</option>
                            <option value="VALIDATOR">VALIDATOR</option>
                            <option value="ADMIN">ADMIN</option>
                    </select>
                    {loading ?<button className='reg-form-btn' type='submit'><FontAwesomeIcon icon={faArrowsRotate} className='spinner'/></button> : <button className='reg-form-btn' type='submit'>Submit</button> }
        </form>
                    
        </div>
    </div>
  );
}

export default CreateUserModal