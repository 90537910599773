
import { Outlet } from 'react-router-dom'
import WebSideNavBar from '../components/webSideNavBar'
import MobileSideNavBar from '../components/mobileSideNavBar'
import NewTopNavComponent from '../components/newTopNavComponent'


const SharedIndexLayout = () => {
  

  return (
    <div  className='sharedlayout-main'>
        <div id="mobile-side-div" className='mobile-side-nav-container' ><MobileSideNavBar/></div>
        <div className='top-section'> <NewTopNavComponent/></div>
        <div className='bottom-section'>
          <div className='left-nav-container'> <WebSideNavBar/> </div>
          <div className='right-outlet-container'><Outlet/></div>
        </div>
    </div>
  )
}

export default SharedIndexLayout