
import { AreaChart, Area, XAxis, YAxis, CartesianGrid,Legend, Tooltip, ResponsiveContainer } from 'recharts';



const SampleLine = ()=> {

  const data =[
    {"date":"01", "numbers":10},
    {"date":"02", "numbers":15},
    {"date":"03", "numbers":40},
    {"date":"04", "numbers":15},
    {"date":"05", "numbers":50},
    {"date":"06", "numbers":20},
    {"date":"07", "numbers":15}
  ]




  return (
    <div className="main-chat-div">

              <ResponsiveContainer width="100%" height="100%">
                  <AreaChart width={500} height={300} data={data} margin={{top: 20,right: 10, left: 10, bottom: 5,}}>
                    <CartesianGrid strokeDasharray="0" vertical={false} horizontal={false}/>
                    <XAxis dataKey="date" axisLine={false} stroke="rgba(255, 255, 255, 0.6)" style={{ fontSize: '0.8rem'}}/>
                    <YAxis axisLine={false} stroke="rgba(255, 255, 255, 0.6)" style={{ fontSize: '0.8rem'}}/>
                    <Tooltip />
                    <Area type="monotone" dataKey="numbers" stroke="rgba(255, 255, 255, 0.6)" strokeWidth={2} fill="transparent" />
                  </AreaChart>
              </ResponsiveContainer>  
    </div>
  );
}

export default SampleLine;




