import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import Alert from '../alert';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import {useState, useEffect} from 'react'
import CURRENCY from "../../config.js/currency";

const LoadDealerAirtimeModal = ({setShowModal, forceUpdate, data}) => {
    const VERIFICATION_URL = `webtrans/recharge_account/${data?.id}`;
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [loading, setLoading] =useState(false)
    const options = CURRENCY
    const [currency, setCurrency] = useState("")
    const [discount, setDiscount] = useState("")
    const [airtime, setAirtime] = useState("")
    const [amountPaid, setAmountPaid] = useState("")
    const [gateway, setGateway] = useState("")
    const axiosPrivate  = useAxiosPrivate()



    useEffect(()=>{
        setErrorMessage("")
    },[]) 


    const handleClose =()=>{
        setShowModal(false)
    }

    const handleSetDiscount =async(e)=>{
        e.preventDefault()
        setErrorMessage("")
        setSuccessMessage("")
        setLoading(true)
        const sdata = {
            currency:currency,
            amount_paid:amountPaid,
            airtime: airtime,
            gateway:gateway
        }

        try{
            const response = await axiosPrivate.post(VERIFICATION_URL,{...sdata})
            setSuccessMessage(response?.data.message)
            setAirtime('')
            setAmountPaid('')
            setDiscount('')
            setCurrency('')
            setLoading(false)
            forceUpdate()
        }
        catch(err){
            if (!err?.response) {
                setErrorMessage("No server response!");
                setLoading(false)
            }
            else{
                setErrorMessage(err.response?.data.message)
                setLoading(false)
            }
        }
        
  
    }

  return (
    <div className="inner-modal-main">
        <div className='inner-modal-body smallc'>
            <button className="inner-close-modal-btn" onClick={handleClose}><FontAwesomeIcon icon={faCircleXmark} /></button>
            {errorMessage && <Alert message={errorMessage} setMessage={setErrorMessage} type={"error"}/>}
            {successMessage && <Alert message={successMessage} setMessage={setSuccessMessage} type={"success"}/>}
            <div className='edit-user-form-container'>
        
                    <form className='edit-user-form' onSubmit={handleSetDiscount}>
                        <div className="modal-heading">Recharge Account for {data?.company_profile?.name || "Anonymous"}</div>
                        <div className="input-group">
                            <span className="input-group-text" id="basic-addon3"><i className="bi bi-currency-dollar"></i></span>
                            <input className='border' type='number'  name='airtime'  value={airtime} onChange={(e)=>setAirtime(e.target.value)} placeholder='Airtime'  required/>
                        </div>
                        <div className="input-group">
                            <span className="input-group-text" id="basic-addon4"><i className="bi bi-currency-dollar"></i></span>
                            <input className='border' type='number'  name='amountPaid'  value={amountPaid} onChange={(e)=>setAmountPaid(e.target.value)} placeholder='Amount Paid'  required/>
                        </div>
                        <div className="input-group">
                            <span className="input-group-text" id="basic-addon5"><i className="bi bi-percent"></i></span>
                            <input className='border' type='number'  name='discount'  value={discount} onChange={(e)=>setDiscount(e.target.value)} placeholder='Discount Applied'  required/>
                        </div>
                        <div className="input-group">
                            <span className="input-group-text" id="basic-addon6"><i className="bi bi-currency-exchange"></i></span>
                            <select className='border' value={currency} onChange={(e)=>setCurrency(e.target.value)}   required>
                                    <option value="">currency</option>
                                    {options && options.map((opt)=>{ return <option value={opt}>{opt}</option>})}
                            </select>
                        </div>
                        <div className="input-group">
                            <span className="input-group-text" id="basic-addon6"><i className="bi bi-door-open"></i></span>
                            <select className='border' value={gateway} onChange={(e)=>setGateway(e.target.value)}   required>
                                    <option value="">gateway</option>
                                    <option value="ECOCASH">ECOCASH</option>
                                    <option value="BANK">BANK</option>
                                    
                            </select>
                        </div>

                        <button type='submit' className='edit-user-btn'>Submit</button>

                    </form>
            </div>
        </div>
    </div>
  );
}

export default LoadDealerAirtimeModal