import React from 'react'

const ApiClient = ({data}) => {
  return (
    <div className='airtime-acc-container'>
    <div className='airtime-account full-leng'>
        <div className='acc-info'>Name <span>{data?.client_name}</span></div>
        <div className='acc-info'>ID<span>{data?.client_id}</span></div>
        <div className='acc-info'>Status<span>{data?.status}</span></div>
    </div>
</div>
  ) 
}

export default ApiClient