import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import Alert from '../alert';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import {useState, useEffect} from 'react'
import CURRENCY from "../../config.js/currency"

const EcocashPurchaseModal = ({setShowModal, forceUpdate}) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const [topUp, setTopUp] = useState('')
    const [billingNumber, setBillingNumber] = useState('')
    const [currency, setCurrency] = useState('')
    const [loading, setLoading] =useState(false)
    const axiosPrivate  = useAxiosPrivate()
    const options = CURRENCY



    useEffect(()=>{
        setErrorMessage("") 
    },[topUp,currency,billingNumber]) 


    const handleClose =()=>{
        setShowModal(false)
    }

    const handleTopUp= async(e)=>{
        e.preventDefault()
        setLoading(true)
        setErrorMessage("")
        setSuccessMessage("")
        let data={
          amount: topUp,
          currency : currency,
          mobile_number : billingNumber
     }
  
        try{
            const response = await axiosPrivate.post(`/webtrans/eco_purchase_airtime`, {...data})
            setSuccessMessage(response?.data?.message)
            setTopUp("")
            setCurrency("")
            setBillingNumber("")
            forceUpdate()
            setLoading(false)
        }catch(err){
          setErrorMessage(err?.response?.data?.message)
          setLoading(false)
        }
        }

  return (
    <div className="inner-modal-main">
        <div className='inner-modal-body smallc'>
            <button className="inner-close-modal-btn" onClick={handleClose}><FontAwesomeIcon icon={faCircleXmark} /></button>
            {errorMessage && <Alert message={errorMessage} setMessage={setErrorMessage} type={"error"}/>}
            {successMessage && <Alert message={successMessage} setMessage={setSuccessMessage} type={"success"}/>}
            <div className='edit-user-form-container'>
        
        <form className='edit-user-form' onSubmit={handleTopUp}>
            <div className="modal-heading">Recharge Account</div>
            <div className="input-group">
                <span className="input-group-text" id="basic-addon3"><i className="bi bi-telephone"></i></span>
                <input className='border' type='text'  name='billing'  value={billingNumber} onChange={(e)=>setBillingNumber(e.target.value)} placeholder='Ecocash Acc (263...)'  required/>
            </div>
            <div className="input-group">
                <span className="input-group-text" id="basic-addon4"><i className="bi bi-currency-dollar"></i></span>
                <input className='border' type='number'  name='topUp'  value={topUp} onChange={(e)=>setTopUp(e.target.value)} placeholder='Airtime Amount'  required/>
            </div>
            <div className="input-group">
                <span className="input-group-text" id="basic-addon6"><i className="bi bi-currency-exchange"></i></span>
                <select className='border' value={currency} onChange={(e)=>setCurrency(e.target.value)}   required>
                        <option value="">currency</option>
                        {options && options.map((opt)=>{ return <option value={opt}>{opt}</option>})}
                </select>
            </div>

            {loading ?<button className='edit-user-btn' type='submit'><FontAwesomeIcon icon={faArrowsRotate} className='spinner'/></button> : <button className='edit-user-btn' type='submit'>top-up</button> }

        </form>
</div>
                    
        </div>
    </div>
  );
}


export default EcocashPurchaseModal