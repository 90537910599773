import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import Alert from '../alert';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import {useState, useEffect} from 'react'
import CURRENCY from "../../config.js/currency"
import useAuth from '../../hooks/useAuth';


const OtherPurchaseModal = ({setShowModal, forceUpdate}) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [amount, setAmount] = useState('')
    const [pop, setPop] = useState('')
    const [currency, setCurrency] = useState('')
    const [loading, setLoading] =useState(false)
    const axiosPrivate  = useAxiosPrivate()
    const options = CURRENCY



    useEffect(()=>{
        setErrorMessage("")
    },[]) 


    const handleClose =()=>{
        setShowModal(false)
    }

    const handleOtherTopUp= async(e)=>{
        e.preventDefault()
        setLoading(true)
        setErrorMessage("")
        setSuccessMessage("")

     let formdata = new FormData()
     formdata.append('popdoc', pop)
     formdata.append('amount', amount)
     formdata.append('currency', currency)
     const headers = {
      'Content-Type': 'multipart/form-data'
    }
  
        try{
          const response = await axiosPrivate.post('webtrans/other_purchase_airtime', formdata, {headers:{...headers}})
            setSuccessMessage(response?.data?.message)
            setAmount('')
            setCurrency('')
            setPop('')
            forceUpdate()
            setLoading(false)
        }catch(err){
          setErrorMessage(err.response?.data?.message)
          setLoading(false)
        }
        }

  return (
    <div className="inner-modal-main">
        <div className='inner-modal-body smallc'>
            <button className="inner-close-modal-btn" onClick={handleClose}><FontAwesomeIcon icon={faCircleXmark} /></button>
            {errorMessage && <Alert message={errorMessage} setMessage={setErrorMessage} type={"error"}/>}
            {successMessage && <Alert message={successMessage} setMessage={setSuccessMessage} type={"success"}/>}
            <div className='edit-user-form-container'>
        
                <form className='edit-user-form' onSubmit={handleOtherTopUp}>
                    <div className="modal-heading">Recharge Account</div>
                    <div className="input-group">
                        <span className="input-group-text" id="basic-addon4"><i className="bi bi-currency-dollar"></i></span>
                        <input className='border' type='number'  name='amount'  value={amount} onChange={(e)=>setAmount(e.target.value)} placeholder='Amount'  required/>
                    </div>

                    <div className="input-group">
                        <span className="input-group-text" id="basic-addon6"><i className="bi bi-currency-exchange"></i></span>
                        <select className='border' value={currency} onChange={(e)=>setCurrency(e.target.value)}   required>
                                <option value="">currency</option>
                                {options && options.map((opt)=>{ return <option value={opt}>{opt}</option>})}
                        </select>
                    </div>
                    <div className="form-text" id="basic-addon4">Upload Proof of Payment</div>
                    <div className="input-group">
                        <span className="input-group-text" id="basic-addon3"><i className="bi bi-cloud-arrow-up"></i></span>
                        <input className='border ' type='file' accept="application/pdf"  name='pop'  onChange={(e)=>setPop(e.target.files[0])} placeholder='Pop'  required/>
                    </div>
                    
                    {loading ?<button className='edit-user-btn' type='submit'><FontAwesomeIcon icon={faArrowsRotate} className='spinner'/></button> : <button className='edit-user-btn' type='submit'>top-up</button> }

                </form>
        </div>
                    
        </div>
    </div>
  );
}


export default OtherPurchaseModal