
import SampleTicket from "../components/sampleTickets";
import NewTicketModal from "../components/modals/newTicketModal";
import useAxiosFetch from "../hooks/useAxiosFetch";
import { useEffect, useState, useReducer } from "react";
import useAuth from "../hooks/useAuth";
import ROLES from "../config.js/roles";
import DocumentTitle from '../hooks/DocumentTitle';

const TicketsPage = () => {
      DocumentTitle("Tickets");
      const { auth} = useAuth();
      const [modal, setModal] = useState(false);
      const [tickets, setTickets] = useState(null);
      const [_, forceUpdate] = useReducer((x) => x + 1, 0);
      const { data } = useAxiosFetch(auth?.role === ROLES.user ? `/ticket/all_tickets_by_user/${auth?.user_id}` : `/ticket/all_tickets/`,_);
  
  useEffect(() => {
          setTickets(data?.data);
        }, [data]);
        

      const handleDisplay = () => {
        setModal(true);
      };
  return (
    <div className="my-tickets-main">
      <div className="my-tickets-container">
        <div className="new-ticket-div">
          <button className="new-ticket-btn" onClick={handleDisplay}>New Ticket</button>
        </div>
        <div className="my-tickets-data">
          <SampleTicket data={tickets || []} title={'My'}  />
        </div>
      </div>
      {modal && <NewTicketModal  setModalToo={setModal} forceUpdate={forceUpdate}/>}
    </div>
  );
};

export default TicketsPage;
