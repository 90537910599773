import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";

const EcoVerificationModal = ({setShowModal, data}) => {
    const transDate = new Date(data?.transactionDate)
    const handleClose =()=>{
        setShowModal(false)
    }
  return (
    <div className="any-modal-main">
      <div className='sale-modal-body'>
        <button className="sale-close-modal-btn" onClick={handleClose}><FontAwesomeIcon icon={faCircleXmark} /></button>
        <div className='with-data'>
                <div className='info-strip'>
                        <div className='user-info fullc'>
                            <div className='info-header'>Refrence ID</div>
                            <div className='info-details'>{data?.clientCorrelator}</div>
                        </div>

                </div>
                <div className='info-strip'>
                       <div className='user-info'>
                            <div className='info-header'>Mobile</div>
                            <div className='info-details'>{data?.endUserId}</div>
                        </div>

                        <div className='user-info'>
                            <div className='info-header'>Currency</div>
                            <div className='info-details'>{data?.paymentAmount?.charginginformation?.currency}</div>
                        </div>
                        <div className='user-info'>
                            <div className='info-header'>Status</div>
                            <div className='info-details'>{data?.transactionOperationStatus}</div>
                        </div>

                </div>
                <div className='info-strip'>
                        <div className='user-info'>
                            <div className='info-header'>To</div>
                            <div className='info-details'>{data?.paymentAmount?.chargeMetaData?.onBeHalfOf}</div>
                        </div>
                        <div className='user-info'>
                            <div className='info-header'>Date</div>
                            <div className='info-details'>{transDate.toDateString()}</div>
                        </div>

                </div>
                <div className='info-strip'>
                        <div className='user-info'>
                            <div className='info-header'>Ammount</div>
                            <div className='info-details numc'>{data?.paymentAmount?.totalAmountCharged}</div>
                        </div>

                </div>
            </div>
        </div>
    </div>
  );
}

export default EcoVerificationModal