import { useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import CURRENCY from "../../config.js/currency";

const SellAirtimeForm = ({  forceUpdate,  setSuccessMessage,  setErrorMessage,}) => {
  const [amount, setAmount] = useState("");
  const [recievingNumber, setRecievingNumber] = useState("");
  const [sellCurrency, setSellCurrency] = useState("");
  const [loadingToo, setLoadingToo] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const options = CURRENCY;

  const handleSale = async (e) => {
    e.preventDefault();
    setLoadingToo(true);
    setErrorMessage("");
    setSuccessMessage("");
    let data = {
      amount: amount,
      currency: sellCurrency,
      reciever_number: recievingNumber,
    };

    try {
      const response = await axiosPrivate.post(`/webtrans/sell_airtime`, {...data });
      setSuccessMessage(response?.data?.message);
      forceUpdate();
      setAmount("")
      setRecievingNumber("")
      setSellCurrency("")
      setLoadingToo(false);
    } catch (err) {
      forceUpdate();
      setErrorMessage(err.response?.data?.message);
      setLoadingToo(false);
    }
  };
  return (
    <div className="edit-user-form-container">
      <form className="edit-user-form" onSubmit={handleSale}>
        <div className="input-group">
          <span className="input-group-text" id="basic-addon2"><i className="bi bi-telephone"></i></span>
          <input className="border" type="text" name="recievingNumber" value={recievingNumber} onChange={(e) => setRecievingNumber(e.target.value)} placeholder="Reciever Number (263...)" required/>
        </div>

        <div className="input-group">
          <span className="input-group-text" id="basic-addon2"><i className="bi bi-currency-dollar"></i></span>
          <input className="border" type="number" name="amount" value={amount} onChange={(e) => setAmount(e.target.value)} placeholder="amount" required/>
        </div>

        <div className="input-group">
          <span className="input-group-text" id="basic-addon3"> <i className="bi bi-currency-exchange"></i></span>
          <select className="border" value={sellCurrency} onChange={(e) => setSellCurrency(e.target.value)} placeholder="Amount" required>
            <option value="">currency</option>
            {options && options.map((opt) => {
                return <option value={opt}>{opt}</option>;
              })}
          </select>
        </div>

        {loadingToo ? <button className="edit-user-btn" type="submit"> <FontAwesomeIcon icon={faArrowsRotate} className="spinner" /> </button> : <button className="edit-user-btn" type="submit"> Sell</button>}
      </form>
    </div>
  );
};

export default SellAirtimeForm;
