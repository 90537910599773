import React from 'react'
import { useState, useEffect,useReducer } from 'react';
import useAxiosFetch from '../../hooks/useAxiosFetch'
import ChangeRequestTable from './newChangeRequests';
import UpdateChangeRequestModal from '../modals/updateChangeRequestModal';

const ApprovalComponent = () => {
  const [showModal,setShowModal] = useState(null)
  const [selectedRow,setSelectedRow] = useState(null)

    const [stats, setStats] = useState(null);
    const [_, forceUpdate] = useReducer((x) => x + 1, 0);

    const { data } = useAxiosFetch(`/cons/change_requests`, _);
    useEffect(() => {
      setStats(data.data);
    }, [data]); 


  return (
      <>
        <div className="section-header">
          <div className="sub-header">All New Approvals</div>
          <div className="main-header">Pending Change Requests</div>
        </div>
        <div className='actual-constants fullc'>
         {stats && <ChangeRequestTable setSelectedRow={setSelectedRow} setShowModal={setShowModal}  data={stats}/>}
        </div>
        
        {showModal && selectedRow && <UpdateChangeRequestModal setShowModal={setShowModal} forceUpdate={forceUpdate} data={selectedRow}/>}
        
      </>
  )
}

export default ApprovalComponent