import {useEffect,useState, useReducer} from 'react'
import useAxiosFetch from '../../hooks/useAxiosFetch';
import ChangeTrackerTable from '../../components/tables/changeTrackerTable';
import DocumentTitle from '../../hooks/DocumentTitle';

const ChangeTrackerReport = () => {

  DocumentTitle("Change Tracker Report");
  const [stats, setStats] = useState(null);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const { data} = useAxiosFetch("/rep/change_trackers",_);

  useEffect(() => { 
      setStats(data?.data); 
    }, [data]);
        
    
  return (
    <div className="user-main-container">
    <div className="user-container">
      <div className="user-header">
        <div className="sub-header">All Time</div>
        <div className="main-header">Change Tracker Report</div>      
      </div>
      <div className="user-table-container rep">
      {stats && <ChangeTrackerTable data={stats}/>}
      </div>
    </div>
  </div>
  );
}

export default ChangeTrackerReport