import React from 'react'
import DocumentTitle from '../hooks/DocumentTitle'
import EditUserForm from '../components/forms/editUserForm'
import EditProfileForm from '../components/forms/editProfileForm'
import EditPasswordrForm from '../components/forms/resetPasswordForm'
import GenerateAPIClientForm from '../components/forms/generateAPIClientForm'
import ApiClient from '../components/apiClient'
import AirtimeRegForm from '../components/forms/airtimeRegForm'
import Alert from '../components/alert';
import useAxiosFetch from '../hooks/useAxiosFetch'
import { useState, useEffect,useReducer } from 'react';
import useAuth from '../hooks/useAuth';
import ApiModal from '../components/modals/apiModal'
import AirtimeAccounts from '../components/airtimeAccounts'

const ProfilePage = () => {
  DocumentTitle('Edit Details')
  const { auth } = useAuth();
  const [stats, setStats] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState(null)
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  
  const { data } = useAxiosFetch(`/web/current_user/${auth?.user_id}`, _);

  useEffect(() => {
    setStats(data?.data);
  }, [data]);
 


  return (
    <div className='profile-main'>
      {errorMessage && <Alert message={errorMessage} setMessage={setErrorMessage} type={"error"}/>}
      {successMessage && <Alert message={successMessage} setMessage={setSuccessMessage} type={"success"}/>}

      <div className='user-details-container'>
        <div className='details-section'>
          <div className='hearder-txt'>User Details</div>
         <div className='info-div'>{stats && <EditUserForm data={stats} forceUpdate={forceUpdate} setErrorMessage={setErrorMessage} setSuccessMessage={setSuccessMessage}/>}</div>
        </div>
        <div className='details-section'>
          <div className='hearder-txt'>Profile Details</div>
          <div className='info-div'>{stats &&   <EditProfileForm data={stats?.company_profile} forceUpdate={forceUpdate} setErrorMessage={setErrorMessage} setSuccessMessage={setSuccessMessage}/>}</div>
        </div>
      </div>
      
      <div className='user-details-container'>
        <div className='details-section'>
          <div className='hearder-txt'>Reset Password</div>
           <div className='info-div'>{stats &&  <EditPasswordrForm setErrorMessage={setErrorMessage} setSuccessMessage={setSuccessMessage}/>}</div>
        </div>
        <div className='details-section'>
          <div className='hearder-txt'>API Client</div>
          {stats && stats?.api_credential ? <div className='info-div'> <ApiClient data={stats?.api_credential}forceUpdate={forceUpdate} setErrorMessage={setErrorMessage} setSuccessMessage={setSuccessMessage}/></div> : <div className='info-div'><GenerateAPIClientForm data={stats} forceUpdate={forceUpdate} setShowModal={setShowModal} setErrorMessage={setErrorMessage} setSuccessMessage={setSuccessMessage} setModalData={setModalData}/></div>}
        </div>
      </div>
      
      <div className='user-details-acc-container'>
        <div className='details-actual'>
          <div className='hearder-txt'>AirTime Accounts</div>
          {stats &&    <div className='reg-div'><AirtimeRegForm forceUpdate={forceUpdate} setErrorMessage={setErrorMessage} setSuccessMessage={setSuccessMessage}/></div>}
          {stats &&   <div className='acc-info-div'><AirtimeAccounts data={stats?.airtime_accounts}/></div>}
        </div>
      </div>

      { modalData && showModal && <ApiModal setShowModal={setShowModal} data={modalData}/>}
      
    </div>
  )
}

export default ProfilePage