import DocumentTitle from '../../hooks/DocumentTitle';
import useAxiosFetch from '../../hooks/useAxiosFetch'
import { useState, useEffect,useReducer } from 'react';
import useAuth from '../../hooks/useAuth';
import BankPurchaseRepTable from '../../components/tables/bankPurchaseRepTable';
import ROLES from '../../config.js/roles';

const BankPurchasesReport = () => {
    DocumentTitle("Bank Purchases Report");
    const { auth} = useAuth();
    const [stats, setStats] = useState(null);
    const [_, forceUpdate] = useReducer((x) => x + 1, 0);


  
    const { data } = useAxiosFetch(auth?.role=== ROLES.user  ? `/webtrans/bank_purchases/${auth?.user_id}` :`/webtrans/bank_purchases`, _);


  useEffect(() => {
    setStats(data?.data);
  }, [data]);

  return (
    <div className="user-main-container">
    <div className="user-container">
      <div className="user-header">
        <div className="sub-header">All Time</div>
        <div className="main-header">Bank Purchases Report</div>      
      </div> 
      <div className="user-table-container rep">
         {stats && <BankPurchaseRepTable data={stats} />}
      </div>
    </div>
  </div>
  )
}

export default BankPurchasesReport