
import DocumentTitle from '../hooks/DocumentTitle'
import SellAirtimeForm from '../components/forms/sellAirtimeForm';
import AirtimeAccounts from '../components/airtimeAccounts';
import useAxiosFetch from '../hooks/useAxiosFetch'
import { useState, useEffect,useReducer } from 'react';
import useAuth from '../hooks/useAuth';
import Alert from '../components/alert';

const OperationsPage = () => {
    DocumentTitle("Operations");
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const { auth } = useAuth();
  const [stats, setStats] = useState(null);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  
  const { data  } = useAxiosFetch(`/web/airtime_account/${auth?.user_id}`, _);

  useEffect(() => { 
    setStats(data?.data);
  }, [data]);

  return (
    <div className="operations-main-container">
        {errorMessage && <Alert message={errorMessage} setMessage={setErrorMessage} type={"error"}/>}
        {successMessage && <Alert message={successMessage} setMessage={setSuccessMessage} type={"success"}/>}
      <div className="top-part"> 
          <div className="info-section">
            <div className="sample-header">
                <div className="sub-header">Sell </div>
                <div className="main-header">Sell Airtime</div>
            </div>
            <div className='form-cont'>
              <SellAirtimeForm setErrorMessage={setErrorMessage} setSuccessMessage={setSuccessMessage} forceUpdate={forceUpdate}/>  
            </div>
          </div>
      </div>

      <div className="bottom-section">
        <div className="sample-header">
          <div className="sub-header">All Accounts</div>
          <div className="main-header">Airtime Accounts</div>
        </div>
        <div className="sample-container"> {stats && <AirtimeAccounts data={stats} /> }</div>
      </div>
    </div>
  );
}

export default OperationsPage