import React from 'react'
import { useState, useEffect,useReducer } from 'react';
import Alert from '../alert';
import useAxiosFetch from '../../hooks/useAxiosFetch'

import NewProfileUpdatesTable from './newProfileRequest';


const VerificationComponent = () => {

    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const [stats, setStats] = useState(null);
    const [_, forceUpdate] = useReducer((x) => x + 1, 0);

    const { data} = useAxiosFetch(`/admin/pending_profiles`, _);
    useEffect(() => {
      setStats(data.data);
    }, [data]);


  return (
      <>
        {errorMessage && <Alert message={errorMessage} setMessage={setErrorMessage} type={"error"}/>}
        {successMessage && <Alert message={successMessage} setMessage={setSuccessMessage} type={"success"}/>}
        <div className="section-header">
          <div className="sub-header">All New Approvals</div>
          <div className="main-header">Company profile Requests</div>
        </div>
        <div className='actual-constants fullc'>
          {stats && <NewProfileUpdatesTable data={stats}/>}
        </div>
      </>
  )
}

export default VerificationComponent