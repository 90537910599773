import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";

const SalesModal = ({setShowModal, data}) => {
    const handleClose =()=>{
        setShowModal(false)
    }
  return (
    <div className="any-modal-main">
      <div className='sale-modal-body'>
        <button className="sale-close-modal-btn" onClick={handleClose}><FontAwesomeIcon icon={faCircleXmark} /></button>
        <div className='with-data'>
                <div className='info-strip'>
                        <div className='user-info'>
                            <div className='info-header'>Full Name</div>
                            <div className='info-details'>{data?.user?.first_name} {data?.user?.last_name}</div>
                        </div>
                        <div className='user-info'>
                            <div className='info-header'>Email</div>
                            <div className='info-details'>{data?.user?.email}</div>
                        </div>
                        <div className='user-info'>
                            <div className='info-header'>Account #</div>
                            <div className='info-details'>{data?.account_number}</div>
                        </div>
                </div>
                <div className='info-strip'>

                        <div className='user-info'>
                            <div className='info-header'>Currency</div>
                            <div className='info-details'>{data?.currency}</div>
                        </div>
                        <div className='user-info'>
                            <div className='info-header'>Status</div>
                            <div className='info-details'>{data?.transaction_status}</div>
                        </div>
                        <div className='user-info'>
                            <div className='info-header'>Ref ID</div>
                            <div className='info-details'>{data?.reference_id}</div>
                        </div>

                </div>
                <div className='info-strip'>
                        <div className='user-info'>
                            <div className='info-header'>Mobile</div>
                            <div className='info-details'>{data?.reciever_number}</div>
                        </div>

                        <div className='user-info'>
                            <div className='info-header'>Channel</div>
                            <div className='info-details'>{data?.channel}</div>
                        </div>
                        <div className='user-info'>
                            <div className='info-header'>Ammount Purchased</div>
                            <div className='info-details numc'>{data?.amount_sold}</div>
                        </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default SalesModal