
import DocumentTitle from '../hooks/DocumentTitle'
import ConstantsSecComponent from '../components/settings_components.js/constantsSecComponent';
import NotificationUserComponent from '../components/settings_components.js/notificationUsersComponent';


const SettingsPage = () => {
    DocumentTitle('settings')


    return (
     <div className='settings-page'>
       <div className='settings-sections'>
         <ConstantsSecComponent/>
       </div>

       <div className='settings-sections'>
         <NotificationUserComponent/>
       </div>
    </div>
  )
}

export default SettingsPage