
import { useNavigate } from 'react-router-dom';
import DocumentTitle from '../hooks/DocumentTitle';
import useAxiosFetch from '../hooks/useAxiosFetch'
import { useState, useEffect,useReducer } from 'react';
import useAuth from '../hooks/useAuth';
import ROLES from '../config.js/roles';
import PopPurchaseTable from '../components/tables/PopPuchasesTable';


const BankPurchasePage = () => {
    DocumentTitle("Bank Purchases");
    const navigate = useNavigate()
    const { auth} = useAuth();
    const [stats, setStats] = useState(null);
    const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  
  const { data} = useAxiosFetch(auth?.role=== ROLES.user  ? `/webtrans/bank_purchases/${auth?.user_id}` :`/webtrans/bank_purchases`, _);

  useEffect(() => {
    setStats(data?.data);
  }, [data]);
    const handleSale = ()=>{
        navigate('/profile')
    }

  return (
    <div className="sales-main-container">
      <div className="sales-container">
        <div className="sales-header">
          <div className="sub-header">All Time </div>
          <div className="main-header">
            <div className='actual-header'>Bank Purchase transactions</div>
            <button className='sales-btn' onClick={handleSale}>Top Up</button>
          </div>
        </div>
        <div className="sales-table-container">
           {stats && <PopPurchaseTable data={stats}/>}
        </div>  
      </div>
    </div>
  );
}

export default BankPurchasePage