import { useState, useEffect } from "react";
import axios from "axios";
import useAxiosPrivate from "./useAxiosPrivate";



const useAxiosFetch = (dataUrl,_)=>{
  const axiosPrivate = useAxiosPrivate()
  const [data, setData]= useState([])
  const [fetchError, setFetchError]= useState(null)
  const [isloading, setIsLoading] = useState(false)


 
  useEffect(()=>{
    let isMounted = true
    const source = axios.CancelToken.source()

    const fetchData = async (url)=>{
        setIsLoading(true)
        try{
            // const response = await axios.post(url, postdata, config)
            const response = await axiosPrivate.get(url, {
                cancelToken: source.CancelToken
            })
            if(isMounted){
                setData(response.data)
                setFetchError(null)
            }
        }
        catch(err){
            if(isMounted){
                setFetchError(err.message)
                setData([])
            }
        }finally{
            isMounted && setIsLoading(false)
        }
    }
    
    fetchData(dataUrl)
    
    const cleanUp = ()=>{
        isMounted = false
        source.cancel()
    }
   return cleanUp
  }, [dataUrl,_])

  return {data, fetchError, isloading}
}

export default useAxiosFetch